export interface Payment {
  id: string
  duration: string
  initialMeasure: string
  finalMeasure: string
  energy: number
  cost: number
  causeEnding: string
  reimbursable: number
  type: number
  soc: number
  ticket: string
  invoice: string
  paid: boolean
  order: string
  createdAt: string
  updateAt: string
  station: StationPayment
}

export interface StationPayment {
  alias: string
  customer: CustomerPayment
  connector: ConnectorPayment[]
}

export interface CustomerPayment {
  id: string
  name: string
  contracts: ContractPayment[]
}

export interface ContractPayment {
  reference: string
}

export interface ConnectorPayment {
  connection: number
}

export enum TypeDocument {
  TICKETS = 'tickets',
  INVOICES = 'invoices'
}
