<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps(['operative', 'disabled'])
const status = ref(0)
const isDisconnected = ref(false)
status.value = props.operative
isDisconnected.value = props.disabled
watch(
  () => [props.operative, props.disabled],
  () => {
    status.value = props.operative
    isDisconnected.value = props.disabled
  }
)
</script>

<template>
  <ToggleSwitch
    input-class="active-switch"
    :model-value="status === 1"
    :disabled="isDisconnected"
  />
</template>

<style scoped lang="scss">
:deep(.active) {
  background: var(--darkGreen) !important;
}

:deep(.inactive) {
  background: var(--red) !important;
}

:deep(.scheduled) {
  background: var(--yellow) !important;
}
</style>
