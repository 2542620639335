import { defineStore } from 'pinia'
import router from '@/router'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { DefaultIdentity } from '@/models/auth/api/Auth'
import { ApiService } from '@/services/ApiService'
import { socket } from '@/config/socket'

export const useAuthStore = defineStore('defaultIdentity', {
  state: (): DefaultIdentity => ({
    customerId: '',
    name: '',
    surname: '',
    email: '',
    phone: '',
    language: '',
    onBoarded: false,
    isAuthenticated: false,
    role: '',
    imageProfile: '',
    mailNotifications: 'false'
  }),
  persist: {
    storage: sessionStorage
  },
  getters: {
    isLoggedIn: (state) => state.isAuthenticated
  },
  actions: {
    setSessionStorage(name: string, data: string) {
      sessionStorage.setItem(name, data)
      this.isAuthenticated = !!data
    },
    async login(email: string, password: string) {
      const payload = {
        email,
        password
      }
      const response = await ApiService.authEntity(RouteNamespace.login, payload)
      this.$state.email = response['data'].email
      this.$state.customerId = response['data'].id
      this.$state.phone = response['data'].phone
      this.$state.language = response['data'].language
      this.$state.onBoarded = response['data'].onBoarded
      this.$state.name = response['data'].name
      this.$state.surname = response['data'].surname
      this.$state.role = response['data'].role
      this.$state.isAuthenticated = !!response['data'].accessToken
      this.$state.imageProfile = response['data'].imageProfile
      this.$state.mailNotifications = response['data'].mailNotifications
      this.setSessionStorage('accessToken', response['data'].accessToken)
      this.setSessionStorage('refreshToken', response['data'].refreshToken)
      await router.push({ name: RouteNamespace.dashboard })
    },

    async logout() {
      sessionStorage.clear()
      socket.disconnect()
      await router.push({ name: RouteNamespace.login })
    }
  }
})
