import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useAuthStore } from '@/stores/auth'
import Login from '@/views/pages/auth/Login.vue'
import ForgotPassword from '@/views/pages/auth/ForgotPassword.vue'
import AppLayout from '@/layout/AppLayout.vue'
import Locations from '@/views/pages/locations/Locations.vue'
import Station from '@/views/pages/stations/Station.vue'
import Dashboard from '@/views/pages/dashboard/Dashboard.vue'
import Connectors from '@/views/pages/connectors/Connectors.vue'
import Customers from '@/views/pages/customers/Customers.vue'
import Payments from '@/views/pages/payments/Payments.vue'
import Cards from '@/views/pages/cards/Cards.vue'
import Rates from '@/views/pages/rates/Rates.vue'
import Settings from '@/views/pages/settings/Settings.vue'
import Payment from '@/views/pages/payments/Payment.vue'
import Users from '@/views/pages/users/Users.vue'
import { Roles } from '@/models'

const routes = [
  {
    path: RouteNamespace.default,
    name: RouteNamespace.login,
    component: Login,
    meta: { requiresAuth: false, authorize: [Roles.admin, Roles.superuser, Roles.user] }
  },
  {
    path: '/forgot-password/:id',
    name: RouteNamespace.forgotPassword,
    component: ForgotPassword,
    meta: { requiresAuth: false, authorize: [Roles.admin, Roles.superuser] }
  },
  {
    path: `/${RouteNamespace.dashboard}`,
    component: AppLayout,
    children: [
      {
        path: `/${RouteNamespace.dashboard}`,
        name: RouteNamespace.dashboard,
        component: Dashboard,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.settings}`,
        name: RouteNamespace.settings,
        component: Settings,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.locations}`,
        name: RouteNamespace.locations,
        component: Locations,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.locations}/:id`,
        name: RouteNamespace.location,
        component: Station,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.locations}/:id/${RouteNamespace.station}/:station/:name/${RouteNamespace.connectors}`,
        name: RouteNamespace.connectors,
        component: Connectors,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.cards}`,
        name: RouteNamespace.cards,
        component: Cards,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.rates}`,
        name: RouteNamespace.rates,
        component: Rates,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.payments}`,
        name: RouteNamespace.payments,
        component: Payments,
        meta: { requiresAuth: true, authorize: [Roles.admin] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.payments}/:id`,
        name: RouteNamespace.payment,
        component: Payment,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.customers}`,
        name: RouteNamespace.customers,
        component: Customers,
        meta: { requiresAuth: true, authorize: [Roles.admin] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      },
      {
        path: `/${RouteNamespace.users}`,
        name: RouteNamespace.users,
        component: Users,
        meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser] },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => checkIsAuthorizedToGo(to, from, next)
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: RouteNamespace.noAuth,
    meta: { requiresAuth: true, authorize: [Roles.admin, Roles.superuser, Roles.user] },
    component: Login,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => checkIsAuthorizedToGo(to, from, next)
  }
]

//Feature to handle role privacy
const checkAccessToken = (to: RouteLocationNormalized) => {
  const isAuth = sessionStorage.getItem('accessToken')
  return to.meta.requiresAuth && isAuth
}

const checkRole = (rolesAuthorized: Roles[]) => {
  const authStore = useAuthStore()
  return rolesAuthorized.includes(<Roles>authStore.role)
}

const checkIsAuthorizedToGo = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { logout } = useAuthStore()
  if (checkAccessToken(to)) {
    if (checkRole(to.meta.authorize as Roles[])) {
      next()
    } else next(from)
  } else await logout()
}

export default routes
