export interface Role {
  id?: string
  role: string
}

export enum Roles {
  admin = 'admin',
  superuser = 'superuser',
  user = 'user'
}
