import { getToken, tokenType } from '@/config/axios'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'

export const decodeJWT = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

export const checkValidToken = async (): Promise<number> => {
  const token = getToken(tokenType.ACCESS_TOKEN)
  const refreshToken = getToken(tokenType.REFRESH_TOKEN)
  let decodedToken: number = 0
  let decodedRefreshToken: number = 0
  if (token) {
    decodedToken = decodeJWT(token).exp * 1000
  }
  if (refreshToken) {
    decodedRefreshToken = decodeJWT(refreshToken).exp * 1000
  }
  const now = Date.now()
  if (token && decodedToken > now) {
    return 1
  }
  if (refreshToken && decodedRefreshToken > now) {
    const newTokens = await ApiService.refreshTokens(RouteNamespace.refreshToken)
    sessionStorage.setItem('accessToken', newTokens.data.accessToken)
    sessionStorage.setItem('refreshToken', newTokens.data.refreshToken)
    return 1
  }
  return 0
}
