import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { ChangeAvailabilityBroadcast, UnlockConnectorBroadcast } from '@/models/ocpp/Broadcast'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'

export const useResponsesOcpp = defineStore('responsesOcpp', {
  state: () => ({
    changeAvailability: new Map<string, ChangeAvailabilityBroadcast>(),
    unlockConnector: new Map<string, UnlockConnectorBroadcast>()
  }),
  getters: {
    getChangeAvailability: (state) => state.changeAvailability,
    getChangeAvailabilityByKey: (state) => (key: string) => {
      return state.changeAvailability.get(key)
    },
    getUnlockConnector: (state) => state.unlockConnector,
    getUnlockConnectorByKey: (state) => (key: string) => {
      return state.unlockConnector.get(key)
    }
  },
  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.changeAvailability, (data: ChangeAvailabilityBroadcast) => {
        const { cpId } = data
        const { connectorId } = data.request
        this._setChangeAvailability(cpId, connectorId, data)
      })

      socket.on(TopicNameSpace.unlockConnector, (data: UnlockConnectorBroadcast) => {
        const { cpId } = data
        const { connectorId } = data.request
        this._setUnlockConnector(cpId, connectorId, data)
      })
    },
    _setChangeAvailability(cpId: string, connectorId: number, data: ChangeAvailabilityBroadcast) {
      this.changeAvailability.set(`${cpId}:${connectorId}`, data)
    },
    _setUnlockConnector(cpId: string, connectorId: number, data: UnlockConnectorBroadcast) {
      this.unlockConnector.set(`${cpId}:${connectorId}`, data)
    }
  }
})
