<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from 'vue'
import { type Card } from '@/models'
import { t } from '@/common/i18n'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import BaseInputSwitch from '@/components/ui/BaseInputSwitch.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'

const hidden = ref<boolean>(true)
const cardStations = ref<string[]>([])
const props = defineProps<{
  visible: boolean
  card?: Partial<Card>
  availableStations: { id: string; alias: string }[]
  onConfirmCard: (card: Partial<Card>) => Promise<void>
  type: 'update' | 'create'
}>()

const emit = defineEmits(['card-dialog:close'])

const type = props.type
const { availableStations, visible, card } = toRefs(props)

const cardData = ref<Partial<Card>>(
  card.value || {
    alias: '',
    idTag: '',
    expiryDate: null,
    limitKWh: 0,
    stations: [],
    active: false
  }
)

watch(card, () => {
  cardData.value = { ...card.value }
  cardStations.value = cardData.value.stations
    ? cardData.value.stations
        .map((station) => station.id)
        .filter((id): id is string => id !== undefined)
    : []

  checkRequired()
})

const saveButtonDisabled = ref(true)

const checkRequired = () => {
  const requiredFields = [cardData.value?.alias, cardData.value?.idTag]
  saveButtonDisabled.value = requiredFields.some((field) => !field)
}

const handleClose = () => {
  emit('card-dialog:close')
}

const handleActiveCard = () => async (ev: Event) => {
  cardData.value.active = (ev.target as HTMLInputElement)?.checked || false
}

const confirmCard = async () => {
  const confirmData = {
    ...cardData.value,
    stations: cardStations.value || []
  }
  await props.onConfirmCard(confirmData as Partial<Card>)
}

onMounted(() => {
  checkRequired()
})
</script>

<template>
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">
          {{ type === 'update' ? t('detail.header.editCard') : t('detail.header.addCard') }}
        </p>
      </div>
      <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
        <svg-icon name="rfid-card" size="24" color="white" />
      </div>
    </template>

    <template #body>
      <div class="flex flex-row">
        <div class="field col-6">
          <label class="font-family-light required" for="alias">{{
            t('detail.card.dialog.alias')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              @input="checkRequired"
              v-model="cardData.alias"
              id="alias"
              autofocus
              :placeholder="t('detail.card.dialog.placeholder.alias')"
            />
          </IconField>
        </div>
        <div class="field col-6">
          <label class="font-family-light required" for="alias">{{
            t('detail.card.dialog.identifier')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="rfid-card" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              @input="checkRequired"
              v-model="cardData.idTag"
              id="idTag"
              autofocus
              :placeholder="t('detail.card.dialog.placeholder.identifier')"
            />
          </IconField>
        </div>
      </div>
      <div class="flex flex-row align-items-center">
        <div class="field col-6 p-1">
          <label class="font-family-light" for="name">{{
            t('detail.card.dialog.expiredDateCard')
          }}</label>
          <DatePicker
            v-model="cardData.expiryDate"
            :minDate="new Date()"
            class="shadow-none"
            :placeholder="t('detail.card.dialog.placeholder.expiredDateCard')"
          />
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="cost">{{ t('detail.card.dialog.limitKWH') }}</label>
          <BaseInputNumber
            v-model="cardData.limitKWh"
            showButtons
            suffix=" kWh"
            :min="0"
            :step="0.1"
            :placeholder="t('detail.card.dialog.placeholder.limitCard')"
          />
        </div>
      </div>

      <div class="flex flex-row align-items-center">
        <div class="field col-6">
          <label class="font-family-light" for="stations">{{
            t('detail.card.dialog.stations')
          }}</label>

          <MultiSelect
            class="h-3rem align-items-center"
            v-model="cardStations"
            display="chip"
            :options="availableStations"
            optionLabel="alias"
            optionValue="id"
            :placeholder="t('detail.card.dialog.placeholder.locationsToCard')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </MultiSelect>
        </div>
        <div class="field col-6">
          <div class="h-3rem">
            <label class="font-family-light" for="stations">{{
              t('detail.card.dialog.activation')
            }}</label>
          </div>
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center mb-1">
              <BaseInputSwitch @change="handleActiveCard" v-model="cardData.active" />
              <div class="ml-3 text-600">
                {{
                  cardData.active
                    ? t('detail.card.dialog.status.active')
                    : t('detail.card.dialog.status.inactive')
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleClose"
          @confirm="confirmCard"
          :disabled="saveButtonDisabled"
          :remove="hidden"
        />
      </div>
    </template>
  </BaseDialog>
</template>
