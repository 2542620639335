import { t } from '@/common/i18n'
import { type ChargePointStatus, StationStatus } from '@/models/ocpp/enums'
import type { StationStatusMap } from '@/models/domain/location/enums'

const statusTranslations = {
  Other: t('detail.payments.header.other'),
  Local: t('detail.payments.header.local'),
  Remote: t('detail.payments.header.remote'),
  EVDisconnected: t('detail.payments.header.evdisconnected'),
  PowerLoss: t('detail.payments.header.powerloss'),
  EmergencyStop: t('detail.payments.header.emergencyStop')
}

const typeRoles = {
  admin: t('roles.admin'),
  superuser: t('roles.superuser'),
  user: t('roles.user')
}

export const evaluateStatus = (status: string): string => statusTranslations[status] || ''

export const evaluateRole = (role: string): string => typeRoles[role] || ''

export const getClassForStatusStore = (
  status: ChargePointStatus | StationStatus | StationStatusMap
): string => {
  const statusClasses = {
    Available: 'available',
    Unavailable: 'unavailable',
    Faulted: 'faulted',
    Preparing: 'preparing',
    Reserved: 'reserved',
    SuspendedEVSE: 'suspended',
    SuspendedEV: 'suspended',
    Charging: 'charging',
    Finishing: 'finishing',
    Disconnected: 'disconnected'
  }

  return statusClasses[status] || statusClasses.Disconnected
}

export const getStatusTranslationStore = (
  status: ChargePointStatus | StationStatus | StationStatusMap
): string => {
  const statusTranslations = {
    Available: t('status.available'),
    Unavailable: t('status.unavailable'),
    Faulted: t('status.faulted'),
    Preparing: t('status.preparing'),
    SuspendedEVSE: t('status.suspendedEVSE'),
    SuspendedEV: t('status.suspendedEV'),
    Charging: t('status.charging'),
    Finishing: t('status.finishing'),
    Disconnected: t('status.disconnected')
  }

  return statusTranslations[status] || statusTranslations.Disconnected
}

export const getStatusEnabledHours = (status: number): string => {
  const scheduleTranslations = {
    0: 'enabled',
    1: 'disabled'
  }
  return scheduleTranslations[status] || 'unknown'
}
export const getStatusCard = (status: number): string => {
  const statusRFIDCard = {
    true: 'enabled',
    false: 'disabled'
  }
  return statusRFIDCard[status]
}
export const causeEnding = [
  { name: t('detail.payments.header.other'), status: 'Other', icon: 'emergency' },
  { name: t('detail.payments.header.local'), status: 'Local', icon: 'stop-locally' },
  { name: t('detail.payments.header.remote'), status: 'Remote', icon: 'stop-remote' },
  {
    name: t('detail.payments.header.evdisconnected'),
    status: 'EVDisconnected',
    icon: 'car-disconnected'
  },
  {
    name: t('detail.payments.header.powerloss'),
    status: 'PowerLoss',
    icon: 'charger-disconnected'
  },
  { name: t('detail.payments.header.emergencyStop'), status: 'EmergencyStop', icon: 'emergency' }
]
export const getIconForCauseEnding = (status: string) => {
  const item = causeEnding.find((c) => c.status === status)
  return item ? item.icon : ''
}
