<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import type { PageState } from 'primevue/paginator'
import * as XLSX from 'xlsx'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { causeEnding, evaluateStatus, getIconForCauseEnding } from '@/utils/status'
import { useApi } from '@/stores/api/api'
import clock from '@/assets/lottie/clock.json'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import { type Payment, TypeDocument } from '@/models/domain/payment/api/Payment'
import AppTopbar from '@/layout/AppTopbar.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { usePayments } from '@/stores/api/payments'

const toast = useToast()
const paymentsStore = usePayments()
const { loading } = storeToRefs(useApi())
const { payments } = storeToRefs(paymentsStore)
const totalRecords = ref<number>()
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const expandedRows = ref([])
const paymentDates = ref<Date | Date[] | (Date | null)[] | null | undefined>(null)
const selectedProduct = ref()
const csv = ref()
const exportCSV = () => {
  csv.value.exportCSV()
}
const exportToExcel = () => {
  const data = payments.value.map((p: Payment) => {
    return {
      [t('detail.payments.header.cost')]: `${p.cost} €`,
      [t('detail.payments.header.start')]: new Date(p.createdAt).toLocaleString(),
      [t('detail.payments.header.duration')]: handleDuration(parseInt(p.duration)),
      [t('detail.payments.header.energySupplied')]: `${p.energy} kWh`,
      [t('detail.payments.header.cause_ending')]: evaluateStatus(p.causeEnding)
    }
  })

  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments')

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })

  // Crear un enlace para descargar el archivo
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `payments-report-${new Date().toLocaleDateString()}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const items = [
  {
    label: t('detail.payments.actions.export_excel'),
    command: () => {
      exportToExcel()
    }
  },
  {
    label: 'Export as CSV',
    command: () => {
      exportCSV()
    }
  }
]
const getPayments = async () => {
  try {
    let url = `${RouteNamespace.payments}?limit=${rowsPerPage.value}&offset=${currentPage.value}`

    if (causeEndingFilter.value.length > 0) {
      const causeEndingParam = causeEndingFilter.value.join(',')
      url += `&causeEnding=${encodeURIComponent(causeEndingParam)}`
    }

    if (Array.isArray(paymentDates.value) && paymentDates.value.length === 2) {
      const paymentDatesFormatted = paymentDates.value.map((date) => formatDate(date))
      url += `&start=${paymentDatesFormatted[0]}&end=${paymentDatesFormatted[1]}`
    }

    const result = await ApiService.readAllEntities(url)
    payments.value = result[0]
    totalRecords.value = result[1]
  } catch (error) {
    console.error('Error retrieving payments:', error)
  }
}

const causeEndingFilter = ref<string[]>([])

const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

const handleDatesSelected = (value: Date | Date[] | (Date | null)[] | null | undefined) => {
  if (
    Array.isArray(value) &&
    value.length === 2 &&
    value[0] instanceof Date &&
    value[1] instanceof Date
  ) {
    paymentDates.value = value
    getPayments()
  }
}
const downloadReport = async (payment: Payment, type: TypeDocument) => {
  //TODO: poner un boton para bajar el ticket?
  try {
    loading.value = true
    await ApiService.downloadEntity(
      `${RouteNamespace.files}/${type}/${payment['id']}`,
      payment['id']
    )
  } catch (error) {
    console.error('Error downloading payment report:', error)
    toast.add({
      severity: 'error',
      summary: t('detail.payment.notifications.errorDownload'),
      life: 5000,
      group: 'error-download'
    })
  } finally {
    loading.value = false
  }
}

const handleDuration = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return `${hours}h ${minutes}m ${seconds}s`
}

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getPayments()
}

onMounted(async () => {
  loading.value = true
  await getPayments()
  loading.value = false
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="customer" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.payments') }}</span>
          </div>
          <SplitButton
            icon="pi pi-download"
            :label="t('detail.payments.actions.export')"
            :model="items"
            rounded
            :pt="{
              icon: {
                class: ['text-xl']
              }
            }"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex">
        <div class="flex align-items-center w-full">
          <DatePicker
            class="date-picker mr-2 shadow-none"
            v-model="paymentDates"
            showIcon
            selectionMode="range"
            :manualInput="false"
            dateFormat="dd/mm/yy"
            :placeholder="t('detail.payments.header.rangeDates')"
            @update:model-value="handleDatesSelected"
            showButtonBar
            :maxDate="new Date()"
            @clear-click="getPayments"
          />
          <MultiSelect
            v-model="causeEndingFilter"
            display="chip"
            class="multi-select align-items-center"
            :maxSelectedLabels="6"
            :placeholder="t('detail.payments.header.cause_ending')"
            :options="causeEnding"
            optionLabel="name"
            optionValue="status"
            @change="getPayments"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ slotProps.option.name }}</div>
              </div>
            </template>
          </MultiSelect>
        </div>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:expanded-rows="expandedRows"
      v-model:selection="selectedProduct"
      :value="payments"
      ref="csv"
      editMode="row"
      :rows="10"
      dataKey="id"
      :exportFilename="`payments-${new Date().toLocaleDateString()}`"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.payments.notFound')" />
      </template>
      <Column expander class="table__expander" />
      <Column
        field="cost"
        :header="t('detail.payments.header.cost')"
        header-class="font-bold"
        class="table__cost"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['cost'].toFixed(2)} €` }}
        </template>
      </Column>
      <Column
        field="createdAt"
        :header="t('detail.payments.header.start')"
        header-class="font-bold"
        class="table__start"
      >
        <template #body="slotProps">
          {{ new Date(slotProps['data']['createdAt']).toLocaleString() }}
        </template>
      </Column>
      <Column
        field="duration"
        :header="t('detail.payments.header.duration')"
        header-class="font-bold"
        class="table__duration"
      >
        <template #body="slotProps">
          {{ handleDuration(slotProps['data']['duration']) }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.payments.header.energySupplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['energy']} kWh` }}
        </template>
      </Column>
      <Column
        field="causeEnding"
        :header="t('detail.payments.header.cause_ending')"
        header-class="font-bold"
        class="table__cause"
      >
        <template #body="slotProps">
          <div class="flex align-items-center">
            <svg-icon
              class="mr-2"
              :name="getIconForCauseEnding(slotProps.data.causeEnding)"
              size="24"
              color="#9E9E9E"
            />
            {{ evaluateStatus(slotProps['data']['causeEnding']) }}
          </div>
        </template>
      </Column>
      <Column
        :header="t('detail.payments.header.invoice')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.payments.actions.download_invoice')"
              rounded
              disabled
              class="button button-normal mr-2"
              @click="downloadReport(slotProps.data, TypeDocument.INVOICES)"
            >
              <template #icon>
                <svg-icon name="invoice" size="16" color="#626868" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          :pt="{
            pageButton: ({ props, state, context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
      <template #expansion="slotProps">
        <div class="flex flex-row p-1 justify-content-around">
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="contract" size="18"></svg-icon>
              <span class="font-bold font-bold ml-2"
                >{{ t('detail.payments.header.contract') }}:</span
              >
              <span class="ml-2">{{
                slotProps?.data?.station?.customer?.contracts?.[0]?.reference.toUpperCase() ?? 'N/A'
              }}</span>
            </div>
          </div>
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="reimbursable" color="#EA2839" size="18"></svg-icon>
              <span class="font-bold ml-2">{{ t('detail.payments.header.refund') }}</span>
              <span class="ml-2">{{ `${slotProps['data']['reimbursable']} €` }}</span>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BasePushNotification
    :title="t('detail.payment.notifications.errorDownload')"
    icon-name="restart"
    color="#EA2839"
    group="error-download"
    severity="danger"
    :summary="t('detail.payment.notifications.errorDownload')"
  />
</template>
<style scoped lang="scss">
.checkbox-header {
  width: 1.5rem;
  height: 1.5rem;
}

input[type='checkbox']:active {
  border: 1px solid var(--lightGrayDetail) !important;
  background-color: var(--red) !important;
}

.checkbox-header:checked {
  border: 1px solid var(--lightGrayDetail) !important;
  background-color: var(--red) !important;
}

::v-deep(.p-inputtext) {
  border: 1px solid var(--lightGrayDetail) !important;
}

::v-deep(.p-inputtext) {
  border: 1px solid var(--lightGrayDetail) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__expander) {
  width: 6%;
}

::v-deep(.table__cost) {
  width: 8%;
}

::v-deep(.table__start) {
  width: 11%;
}

::v-deep(.table__duration) {
  width: 12%;
}

::v-deep(.table__energy) {
  width: 17%;
}

::v-deep(.table__cause) {
  width: 37%;
}

::v-deep(.table__actions) {
  width: 9%;
}

::v-deep(.date-picker) {
  min-width: 20rem;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}
</style>
