import axios, { axiosInstance, tokenType } from '@/config/axios'
import type { Login } from '@/models/auth/api/Auth'
import { useAuthStore } from '@/stores/auth'
import { checkValidToken } from '@/utils/validateJWT'
// TODO when the payloads will be clarified, we will have to use DTO interfaces instead of unknown or never types
export const ApiService = {
  async authEntity(nameSpace: string, payload: Login, credentials?: boolean) {
    const axios = axiosInstance(tokenType.ACCESS_TOKEN)
    return await axios.post(`${nameSpace}`, payload, { withCredentials: credentials })
  },
  async refreshTokens(nameSpace: string) {
    const axios = axiosInstance(tokenType.REFRESH_TOKEN)
    return await axios.post(`${nameSpace}`, {
      refreshToken: sessionStorage.getItem('refreshToken')
    })
  },
  // TODO Login is duplicated, remove ASAP
  async forgotPass(nameSpace: string, payload: unknown) {
    try {
      return await axios.post(`${nameSpace}`, payload)
    } catch (error) {
      console.log('error')
      return []
    }
  },
  async downloadEntity(nameSpace: string, id: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return []
      } else {
        return await axios.get(`${nameSpace}`, {
          responseType: 'blob'
        })
      }
    } catch (error) {
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async createEntity(nameSpace: string, payload: unknown) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return
      } else {
        return await axios.post(`${nameSpace}`, payload)
      }
    } catch (error) {
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async totalActions(nameSpace: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return []
      } else {
        return await axios.get(`${nameSpace}`)
      }
    } catch (error) {
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async readAllEntities(nameSpace: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return []
      } else {
        const response = await axios.get(`${nameSpace}`)
        return response.data
      }
    } catch (error) {
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async readEntity(nameSpace: string, id: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return []
      } else {
        const response = await axios.get(`${nameSpace}/${id}`)
        return response.data
      }
    } catch (error) {
      console.log('error')
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async readResourceByEntity(nameSpace: string, id: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return []
      } else {
        const response = await axios.get(`${nameSpace}/${id}/resource`, {
          responseType: 'blob'
        })
        return response.data
      }
    } catch (e) {
      console.log('Error: ', e)
    }
  },
  async updateEntity(nameSpace: string, id: string, payload: unknown) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return
      } else {
        return await axios.patch(`${nameSpace}/${id}`, payload)
      }
    } catch (error) {
      console.log('error', error)
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async updateEntityWithResources(nameSpace: string, id: string, payload: unknown) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return
      } else {
        return await axios.patch(`${nameSpace}/${id}/resource`, payload)
      }
    } catch (error) {
      console.log('error', error)
      return [] // Return an empty array or handle the error in an appropriate way
    }
  },
  async deleteEntity(nameSpace: string, id: string) {
    try {
      if ((await checkValidToken()) === 0) {
        await useAuthStore().logout()
        return
      } else {
        await axios.delete(`${nameSpace}/${id}`)
      }
    } catch (error) {
      console.log('error')
      return [] // Return an empty array or handle the error in an appropriate way
    }
  }
}
