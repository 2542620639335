<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { type availableLanguages, i18n, t } from '@/common/i18n'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { useApi } from '@/stores/api/api'
import AppTopbar from '@/layout/AppTopbar.vue'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import { capitalizeString } from '@/utils/capitalize'
import BaseInputSwitchRows from '@/components/ui/BaseInputSwitchRows.vue'
import { version } from '@/../package.json'

const { customerId, email, phone, name, surname, role, language, imageProfile, mailNotifications } =
  storeToRefs(useAuthStore())
const { loading } = storeToRefs(useApi())
const text = ref<string>('')
const uploadedImage = ref('')
const newProfileImgFile = ref<File | null>(null)
const avatarIcon = ref<string>('')
const lang = ref<availableLanguages>('es')
const handleLanguage = (event: any) => {
  lang.value = event.value
}
const handlePreferences = async () => {
  loading.value = true
  const formData = new FormData()
  if (newProfileImgFile.value) {
    formData.append('imageProfile', newProfileImgFile.value)
  }
  formData.append('name', name.value)
  formData.append('phone', phone.value)
  formData.append('language', language.value)
  formData.append('imageProfile', imageProfile.value)
  formData.append('mailNotifications', mailNotifications.value)
  try {
    const response = await ApiService.updateEntityWithResources(
      RouteNamespace.customers,
      customerId.value,
      formData
    )
    if (response) {
      useAuthStore().imageProfile = response['data']['imageProfile']
    }
    language.value = lang.value
    sessionStorage.setItem('language', lang.value)
    i18n.global.locale = language.value as availableLanguages
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
    //reload page to apply imageProfile changes
    window.location.reload()
  }
}

const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      newProfileImgFile.value = file
      uploadedImage.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
    avatarIcon.value = file.name
  }
  event.files.pop()
}
const resetTutorials = async () => {
  await ApiService.updateEntity(RouteNamespace.customers, customerId.value, { onBoarded: false })
  useAuthStore().onBoarded = false
  window.location.reload()
}
const getPreferences = async () => {
  try {
    const response = await ApiService.readResourceByEntity(
      RouteNamespace.customers,
      customerId.value
    )
    if (response) {
      uploadedImage.value = URL.createObjectURL(response)
    } else {
      console.log('No image received from the server')
    }
  } catch (error) {
    console.log(error)
  }
}

onMounted(() => {
  try {
    loading.value = true
    getPreferences()
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
})
const languages = computed(() => [
  { name: t('detail.settings.languages.es'), code: 'es' },
  { name: t('detail.settings.languages.en'), code: 'en' },
  { name: t('detail.settings.languages.fr'), code: 'fr' },
  { name: t('detail.settings.languages.de'), code: 'de' },
  { name: t('detail.settings.languages.pt'), code: 'pt' },
  { name: t('detail.settings.languages.it'), code: 'it' }
])

const evaluateRole = computed(() => {
  return role.value
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl mb-3">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex align-items-center">
            <svg-icon name="user" size="24" color="#9E9E9E" />
            <span class="font-bold">{{ t('detail.header.settings') }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <Tabs value="0">
        <TabList>
          <Tab value="0">
            <div class="flex justify-content-between align-items-center max-w-10rem">
              <svg-icon name="settings" size="24" />
              <span>{{ t('dashboard.myAccount') }}</span>
            </div>
          </Tab>
          <Tab value="1">
            <div class="flex justify-content-between align-items-center max-w-13rem">
              <svg-icon name="settings" size="24" />
              <span>{{ t('detail.header.settings') }}</span>
            </div>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel value="0">
            <div class="col-12">
              <div
                class="flex flex-row justify-content-between border-bottom-1 border-300 border-gray-300 p-2 text-align-center"
              >
                <div class="text-bold text-xl">{{ t('detail.settings.personalData') }}</div>
              </div>
              <div class="flex flex-row h-5rem">
                <div class="flex flex-row col-5 align-items-center">
                  <svg-icon name="mail" size="16" color="#9E9E9E" />
                  <span class="ml-2 font-bold">{{ t('detail.settings.email') }}</span>
                  <Inplace
                    :closable="true"
                    disabled
                    :pt="{
                      closeButton: {
                        root: 'button-normal'
                      }
                    }"
                  >
                    <template #display>
                      {{ email || t('detail.settings.actions.clickToEdit') }}
                    </template>
                    <template #content>
                      <InputText v-model="text" autofocus class="ml-2 border-0" />
                    </template>
                  </Inplace>
                </div>
                <div class="flex flex-row col-5 align-items-center">
                  <svg-icon name="location-name" size="16" color="#9E9E9E" />
                  <span class="ml-2 font-bold">{{ t('detail.settings.name') }}</span>
                  <Inplace
                    :closable="true"
                    :pt="{
                      closeButton: {
                        root: 'button-normal'
                      }
                    }"
                  >
                    <template #display>
                      {{
                        `${capitalizeString(name)} ${capitalizeString(surname)}` ||
                        t('detail.settings.actions.clickToEdit')
                      }}
                    </template>
                    <template #content>
                      <InputText v-model="name" autofocus class="ml-2 border-0" />
                    </template>
                  </Inplace>
                </div>
              </div>
              <div class="flex flex-row h-5rem">
                <div class="flex flex-row col-5 align-items-center">
                  <svg-icon name="role" size="16" color="#9E9E9E" />
                  <span class="ml-2 font-bold">{{ t('detail.settings.role') }}</span>
                  <span class="ml-2 text-gray-500">{{ evaluateRole }}</span>
                </div>
                <div class="flex flex-row col-5 align-items-center">
                  <svg-icon name="phone" size="16" color="#9E9E9E" />
                  <span class="ml-2 font-bold">{{ t('detail.settings.phone') }}</span>
                  <Inplace
                    :closable="true"
                    :pt="{
                      closeButton: {
                        root: 'button-normal'
                      }
                    }"
                  >
                    <template #display>{{ phone }}</template>
                    <template #content>
                      <InputText v-model="phone" autofocus class="ml-2 border-0" />
                    </template>
                  </Inplace>
                </div>
              </div>
              <div class="flex flex-row h-5rem">
                <div class="flex flex-row col-5 align-items-center">
                  <svg-icon name="image" size="16" color="#9E9E9E" />
                  <span class="ml-2 font-bold">{{ t('detail.settings.profile_image') }}</span>
                </div>
              </div>
              <div class="flex flex-row h-5rem">
                <div class="flex flex-row col-8 justify-content-evenly align-items-center">
                  <div>
                    <Avatar
                      class="border-1 border-100 w-5rem h-5rem"
                      shape="circle"
                      :image="uploadedImage"
                    >
                      <template #default>
                        <svg-icon v-if="!uploadedImage" name="user" size="48" color="#9E9E9E" />
                      </template>
                    </Avatar>
                  </div>
                  <FileUpload
                    class="button button-normal ml-2 border-round-2xl"
                    mode="basic"
                    name="demo[]"
                    url="/api/upload"
                    @select="handleUpload"
                    :multiple="false"
                    accept="image/*"
                    :maxFileSize="1000000"
                    :showCancelButton="false"
                    :showUploadButton="false"
                    :chooseLabel="t('detail.settings.actions.uploadImage')"
                    :pt="{ content: { class: 'hidden' }, buttonbar: { class: 'p-0 border-none' } }"
                  />
                </div>
              </div>
              <section>
                <div class="h-2rem mt-3">
                  <div
                    class="flex flex-row justify-content-between border-bottom-1 border-300 border-gray-300 p-2 text-align-center"
                  >
                    <div class="text-bold text-xl">
                      {{ t('detail.settings.header.preferences') }}
                    </div>
                    <div class="border-bottom-1 cursor-pointer" @click="resetTutorials">
                      {{ t('detail.settings.header.reset') }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-column col-4">
                  <div class="flex flex-row align-items-center h-5rem">
                    <svg-icon name="language" size="16" color="#9E9E9E" />
                    <span class="ml-2 font-bold">{{ t('detail.settings.language') }}</span>
                    <Inplace
                      :closable="true"
                      :pt="{
                        closeButton: {
                          root: 'button-normal'
                        }
                      }"
                    >
                      <template #display>
                        {{ lang }}
                      </template>
                      <template #content>
                        <Select
                          class="ml-2 border-0"
                          v-model="lang"
                          :options="languages"
                          optionLabel="name"
                          optionValue="code"
                          :placeholder="t('detail.settings.actions.language')"
                          @change="handleLanguage"
                        >
                          <template #loadingicon>
                            <svg-icon name="language" size="16" />
                          </template>
                        </Select>
                      </template>
                    </Inplace>
                  </div>
                </div>
                <div class="flex col-3 align-items-center justify-content-between">
                  <BaseInputSwitchRows
                    v-model="mailNotifications"
                    :label="t('detail.settings.header.mailNotifications')"
                    :checked="true"
                  />
                  <span class="font-bold">
                    {{ t('detail.settings.header.mailNotifications') }}
                  </span>
                </div>
                <div class="flex flex-row justify-content-end p-2">
                  <Button
                    :label="t('actions.save')"
                    rounded
                    class="button button-save w-2 mr-4"
                    @click="handlePreferences"
                  />
                </div>
                <div class="flex justify-content-end">
                  <small>{{ version }}</small>
                </div>
              </section>
            </div>
          </TabPanel>
          <TabPanel value="1">
            <iframe
              width="540"
              height="630"
              src="https://e8676cc8.sibforms.com/serve/MUIFAMom63GR0tuRQ4llj5FvyYvMUJnFmxm6VpIDBn0HQaXmINYYwGEsUSl0OQDppCCnHoaXtfXsrE1T-2FZ0I4-bZutq_vM9Kozrxxmvgs_1s4KWoPWld6mLdF8eYTOKlIXPF1D53u73uVGk-oMfnifY_6q8D1eB4ftu_noI0q_OLxCOdWSiPx-0mR14LQDF2a6rhxXcy6fJksY"
              frameborder="0"
              scrolling="auto"
              allowfullscreen
              class="hidden newsletter"
            ></iframe>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </template>
  </AppTopbar>
</template>
<style scoped lang="css">
.newsletter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
</style>
