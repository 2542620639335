<script setup lang="ts">
import { ref, toRefs } from 'vue'

const props = defineProps<{
  modelValue?: boolean
  header?: string
  closable?: boolean
  open?: boolean
}>()

const emit = defineEmits<{
  (e: typeof updateModelValue, value: boolean): void
}>()

const { modelValue, header } = toRefs(props)

const localModelValue = ref(modelValue)

const updateModelValue = 'update:modelValue'

const handleInput = (value: boolean) => {
  localModelValue.value = value // Update the local data property
  emit(updateModelValue, value)
}
</script>

<template>
  <Fluid>
    <Dialog
      v-model:modelValue="localModelValue"
      v-bind="$attrs"
      modal
      :closable="closable"
      :header="header"
      dismissable-mask
      @update:modelValue="handleInput"
    >
      <div>
        <slot name="title"></slot>
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </Dialog>
  </Fluid>
</template>
