<script setup lang="ts">
import { t } from '@/common/i18n'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import BaseCardDashboard from '@/components/ui/BaseCardDashboard.vue'
import BaseCardDetailDashboard from '@/components/ui/BaseCardDetailDashboard.vue'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import type { Dashboard } from '@/models/ui/Dashboard'
import AppTopbar from '@/layout/AppTopbar.vue'

const { loading } = storeToRefs(useApi())
const data = ref<Dashboard>({
  availableStations: 0,
  totalStations: 0,
  unAvailableStations: 0,
  totalCustomers: 0,
  totalLocations: 0,
  totalUsers: 0,
  totalPayments: 0
})

const handleAddNewItem = (route: string) => {
  //push to the route and open the modal to create a new item
  router.push({ name: route, query: { active: 'true' } })
}

const handleRoute = (route: string) => {
  router.push({ name: route })
}

const prepareDashboard = async () => {
  try {
    loading.value = true
    data.value = await ApiService.readAllEntities(RouteNamespace.dashboard)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await prepareDashboard()
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-row justify-content-between">
        <span class="font-bold text-3xl">{{ t('detail.dashboard.profile') }}</span>
      </div>
    </template>
  </AppTopbar>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-4">
      <BaseCardDashboard
        icon="charge"
        iconClass="circle-icon circle-icon__default"
        icon-color="#626868"
        :header="t('dashboard.activeChargePoints')"
        :header-subtitle="t('dashboard.totalActiveChargePoints')"
        :content="t('dashboard.activeChargePoints').toLocaleLowerCase()"
        :content-description="t('dashboard.chargePoints')"
        :visible="false"
        :value="data.availableStations"
        :total="data.totalStations"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <BaseCardDashboard
        icon="connected"
        iconClass="circle-icon circle-icon__available"
        icon-color="#00BFA5"
        :header="t('dashboard.availableChargePoints')"
        :header-subtitle="t('dashboard.totalAvailableChargePoints')"
        :content="t('dashboard.availableChargePoints').toLocaleLowerCase()"
        :content-description="t('dashboard.chargePoints')"
        :visible="false"
        :value="data.availableStations"
        :total="data.totalStations"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <BaseCardDashboard
        icon="warning"
        iconClass="circle-icon circle-icon__unavailable"
        icon-color="#FF5252"
        :header="t('dashboard.unavailableChargePoints')"
        :header-subtitle="t('dashboard.totalUnavailableChargePoints')"
        :content="t('dashboard.unavailableChargePoints').toLocaleLowerCase()"
        :content-description="t('dashboard.chargePoints')"
        :visible="true"
        :value="data.unAvailableStations"
        :total="data.totalStations"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <BaseCardDetailDashboard
        :content-description="t('detail.customer.actions.create')"
        icon="customer"
        iconClass="circle-icon circle-icon__default"
        icon-color="#626868"
        :header="t('dashboard.customers')"
        :header-subtitle="t('dashboard.customersDetail')"
        :content="t('dashboard.activeChargePoints').toLocaleLowerCase()"
        :footerValues="0"
        :value="data.totalCustomers"
        :footer="t('cards.fromLastWeek')"
        footerValueClass="green"
        :hideCreation="true"
        @create="handleAddNewItem(RouteNamespace.customers)"
        @route="handleRoute(RouteNamespace.customers)"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <BaseCardDetailDashboard
        icon="user"
        :content-description="t('detail.user.actions.create')"
        iconClass="circle-icon circle-icon__default"
        icon-color="#626868"
        :header="t('dashboard.users')"
        :header-subtitle="t('dashboard.usersDetail')"
        :content="t('dashboard.unavailableChargePoints').toLocaleLowerCase()"
        :value="data.totalUsers"
        :footerValues="0"
        footerValueClass="green"
        :hideCreation="true"
        @create="handleAddNewItem(RouteNamespace.users)"
        @route="handleRoute(RouteNamespace.users)"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <BaseCardDetailDashboard
        :content-description="t('detail.location.actions.create')"
        icon="location"
        iconClass="circle-icon circle-icon__default"
        icon-color="#626868"
        :header="t('dashboard.locations')"
        :header-subtitle="t('dashboard.locationsDetail')"
        :content="t('dashboard.activeChargePoints').toLocaleLowerCase()"
        :footerValues="0"
        :value="data.totalLocations"
        :footer="t('cards.fromLastWeek')"
        footerValueClass="green"
        :hideCreation="true"
        @create="handleAddNewItem(RouteNamespace.locations)"
        @route="handleRoute(RouteNamespace.locations)"
      />
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <BaseCardDetailDashboard
        :content-description="t('detail.station.actions.create')"
        icon="connector"
        iconClass="circle-icon circle-icon__default"
        icon-color="#626868"
        :header="t('dashboard.payments')"
        :header-subtitle="t('dashboard.paymentsDetail')"
        :content="t('dashboard.activeChargePoints').toLocaleLowerCase()"
        :visible="false"
        :footerValues="0"
        :value="`${data.totalPayments.toFixed(2) || 0} €`"
        :footer="t('cards.fromLastWeek')"
        footerValueClass="green"
        @route="handleRoute(RouteNamespace.payments)"
      />
    </div>
  </div>
</template>
