<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useLayout } from '@/layout/composables/layout'
import { useAuthStore } from '@/stores/auth'
import type { RouteItem } from '@/models/ui/Menu'

const authStore = useAuthStore()
const route = useRoute()
const { layoutState, setActiveMenuItem, onMenuToggle } = useLayout()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
  root: {
    type: Boolean,
    default: true
  },
  parentItemKey: {
    type: String,
    default: null
  }
})

const isActiveMenu = ref<boolean>(false)
const itemKey = ref<string | null>(null)

onBeforeMount(() => {
  itemKey.value = props.parentItemKey
    ? `${props.parentItemKey}-${props.index}`
    : String(props.index)
})

const itemClick = (event: Event, item) => {
  if (item.disabled) {
    event.preventDefault()
    return
  }

  const { overlayMenuActive, staticMenuMobileActive } = layoutState

  if ((item.to || item.url) && (staticMenuMobileActive.value || overlayMenuActive.value)) {
    onMenuToggle()
  }

  if (item.command) item.command({ originalEvent: event, item: item })

  const foundItemKey = item.items
    ? isActiveMenu.value
      ? props.parentItemKey
      : itemKey
    : itemKey.value

  setActiveMenuItem(foundItemKey)
}

const checkActiveRoute = (item: RouteItem) => {
  return (
    route.path.startsWith(<string>item.to) ||
    (item.children && item.children.some(checkActiveRoute))
  )
}

const regularItems = computed(() => {
  return props.item.items?.filter((item) => !item.footerItem) || []
})
</script>
<template>
  <li
    :class="{
      'layout-root-menuitem': root,
      'active-menuitem': isActiveMenu,
      'preference-item': item.footerItem
    }"
  >
    <div v-if="root" class="flex align-items-center layout-menuitem-root-text">
      <svg-icon :name="item['icon']" size="24" />
      <div class="ml-2">{{ item['label'] }}</div>
    </div>
    <a
      v-if="(!item['to'] || item['items']) && item['visible'] !== false"
      :href="item['url']"
      @click="itemClick($event, item)"
      :class="item['class']"
      :target="item['target']"
      tabindex="0"
    >
      <span class="layout-menuitem-text">{{ item['label'] }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item['items']"></i>
    </a>
    <router-link
      v-if="item['to'] && !item['items'] && item['visible'] !== false"
      @click="itemClick($event, item)"
      :class="[item['class'], { 'active-route': checkActiveRoute(item) }]"
      tabindex="0"
      :to="item['to']"
    >
      <svg-icon
        :name="item['icon']"
        size="20"
        :color="checkActiveRoute(item) ? 'white' : '#9E9E9E'"
      />
      <span class="layout-menuitem-text ml-2">{{ item['label'] }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item['items']"></i>
    </router-link>
    <transition v-if="regularItems.length && item['visible'] !== false" name="layout-submenu">
      <ul v-show="root ? true : isActiveMenu" class="flex flex-column pt-1 layout-submenu">
        <template v-for="(child, i) in regularItems">
          <app-menu-item
            v-if="child.roles.includes(authStore.role)"
            :id="child.icon"
            :key="child"
            :index="i"
            :item="child"
            :parentItemKey="itemKey !== null ? itemKey : undefined"
            :root="false"
          />
        </template>
      </ul>
    </transition>
  </li>
</template>
