<script setup lang="ts">
import { type DebuggerEvent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import type { PageState } from 'primevue/paginator'
import { FilterMatchMode } from '@primevue/core/api'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import router from '@/router'
import { useRoute } from 'vue-router'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import { useChargerStatuses, useMeterValues, useStatusNotification } from '@/stores/ocpp'
import {
  evaluateStatus,
  getClassForStatusStore,
  getIconForCauseEnding,
  getStatusTranslationStore
} from '@/utils/status'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { capitalizeString } from '@/utils/capitalize'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BaseGeoLocalization from '@/components/ui/BaseGeoLocalization.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseMapContainer from '@/components/ui/BaseMapContainer.vue'
import type {
  ChargerModel,
  Coordinate,
  Localization,
  Location,
  Rate,
  Station,
  StationCoords
} from '@/models'
import type { ListStationsStatus } from '@/models/domain/station/component/ListStationsStatus'
import {
  ResetType,
  StationStatus,
  UnitOfMeasure,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import type { StatusNotificationMapped } from '@/models/ocpp/StatusNotification'
import { StationStatusMap } from '@/models/domain/location/enums'
import AppTopbar from '@/layout/AppTopbar.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import { socket } from '@/config/socket'

type RouteLocationNormalizedLoaded = import('vue-router').RouteLocationNormalizedLoaded
const emptyLocation = {
  id: '',
  active: 0,
  country: '',
  status: 0,
  name: '',
  postcode: '',
  province: '',
  city: '',
  address: '',
  lat: 0,
  lon: 0,
  phone: '',
  paidService: false,
  station: []
}
const toast = useToast()
const { loading, chargers } = storeToRefs(useApi())
const location = ref<Location>(emptyLocation)
const stations = ref<Station[]>([])
const rates = ref<Rate[]>([])
const stats = ref([])
const expandedRows = ref([])
const selectedProduct = ref()
const newCoords = ref<StationCoords>()
const popup = ref()
const totalStations = ref<number>(0)
const totalPayments = ref<number>(0)
const totalConnectors = ref<number>(0)
const rowsPerStationsPage = ref<number>(10)
const rowsPerPaymentsPage = ref<number>(10)
const currentStationsPage = ref<number>(0)
const currentPaymentsPage = ref<number>(0)
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activeUpdateLocationDialog = ref<boolean>(false)
const paidService = ref<boolean>(false)
const numberConnections = ref<number>(0)
const hidden = ref<boolean>(false)
const coords = ref<Coordinate[]>([])
const stationsSuppliedEnergy = ref(new Map())
const selectedData = ref<Location>(emptyLocation)
const selectedStation = ref({})
const selectedRate = ref<string>('')
const selectedCharger = ref<string>('')
const serialNumber = ref<string>('')
const alias = ref<string>('')
const stationLat = ref<number>(0)
const stationLon = ref<number>(0)
const tabIndex = ref<string>('0')
const payments = ref([])
const allStationsStatus = ref<ListStationsStatus>({})
const stationStatus = ref<number>(0)
const route = useRoute() as RouteLocationNormalizedLoaded & {
  params: {
    id: string
  }
}
const confirm = useConfirm()
const statusNotificationStore = useStatusNotification()
const chargerStatusesStore = useChargerStatuses()
const meterValuesStore = useMeterValues()

const updateAddress = (e: Localization) => {
  if (selectedData.value) {
    selectedData.value['address'] = e.address_line1
    selectedData.value['city'] = e.city
    selectedData.value['country'] = e.country
    selectedData.value['postcode'] = e.postcode
    selectedData.value['province'] = e.county
    selectedData.value['lat'] = e.lat
    selectedData.value['lon'] = e.lon
  }
}

const getLocation = async () => {
  try {
    const { id } = route.params
    location.value = await ApiService.readEntity(RouteNamespace.locations, id)
    selectedData.value = location.value //TODO fix this
    alias.value = location.value.name
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  }
}

const getStations = async () => {
  try {
    const { id } = route.params
    const result = await ApiService.readAllEntities(
      `${RouteNamespace.stations}/${id}/location?limit=${rowsPerStationsPage.value}&offset=${currentStationsPage.value}`
    )
    stations.value = result[0]
    totalStations.value = result[1]
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  }
}
const getPayments = async () => {
  try {
    const { id } = route.params
    const result = await ApiService.readAllEntities(
      `${RouteNamespace.payments}/${id}/location?limit=${rowsPerPaymentsPage.value}&offset=${currentPaymentsPage.value}`
    )
    payments.value = result[0]
    totalPayments.value = result[1]
  } catch (error) {
    console.error('Error retrieving payments:', error)
  }
}
const updateCoords = (newCoords: StationCoords) => {
  stationLat.value = newCoords.lat
  stationLon.value = newCoords.lng
}
const getRates = async () => {
  try {
    rates.value = await ApiService.readAllEntities(RouteNamespace.rates)
  } catch (error) {
    console.error('Error retrieving rates:', error)
  }
}
const handleUpdateLocation = () => {
  activeUpdateLocationDialog.value = true
}
const handleUpdate = (event: Event, rowData: Station) => {
  event.stopPropagation()
  const { lat, lon, status } = rowData
  stationLat.value = lat
  stationLon.value = lon
  stationStatus.value = status
  newCoords.value = { lat, lng: lon }
  activeUpdateDialog.value = true
  selectedStation.value = rowData
}
const handleCancel = () => {
  activeUpdateDialog.value = false
  activeCreationDialog.value = false
  activeUpdateLocationDialog.value = false
}
const confirmReset = (event: Event, cpId: string) => {
  event.stopPropagation()
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('actions.confirmRemove'),
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: t('no'),
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: t('yes')
    },
    accept: () => {
      handleOCPPReset(RouteNamespace.reset, cpId)
    },
    reject: () => {
      console.log('Rejecting operation')
    }
  })
}
const handleOCPPReset = async (route: string, cpId: string) => {
  try {
    loading.value = true
    const payload = {
      cpId,
      type: ResetType.HARD
    }
    await ApiService.createEntity(`${RouteNamespace.ocpp}/${route}`, payload)
    loading.value = false
  } catch (error) {
    console.error('Error resetting station:', error)
  } finally {
    loading.value = false
  }
}
const handleDetail = (row: Event) => {
  router.push({
    name: RouteNamespace.connectors,
    params: { station: row['data']['id'], name: row['data']['alias'] }
  })
}
const confirmInsert = async () => {
  try {
    const payload = {
      locationId: route.params.id,
      customerId: selectedData.value['customerId'],
      alias: alias.value,
      cpId: serialNumber.value, //currently the same as serial number
      serialNumber: serialNumber.value,
      chargerId: selectedCharger.value,
      rateId: selectedRate.value,
      numberConnections: numberConnections.value,
      paidService: paidService.value,
      lat: stationLat.value,
      lon: stationLon.value
    }
    loading.value = true
    await ApiService.createEntity(RouteNamespace.stations, payload)
  } catch (error) {
    console.error('Error creating location:', error)
  } finally {
    activeCreationDialog.value = false
    toast.add({
      group: 'stations',
      severity: 'success',
      detail: t('detail.station.notifications.createSuccess'),
      life: 3000
    })
    await getStations()
    initializeListStationStatus()
    socket.emit('reload')
    loading.value = false
  }
}
const confirmUpdateStation = async () => {
  try {
    const payload = {
      locationId: route.params.id,
      customerId: selectedData.value['customerId'],
      alias: selectedStation.value['alias'],
      cpId: selectedStation.value['serialNumber'],
      serialNumber: selectedStation.value['serialNumber'],
      chargerId: selectedStation.value['charger'],
      rateId: selectedStation.value['rate'],
      numberConnections: selectedStation.value['numberConnections'],
      paidService: selectedStation.value['paidService'],
      lat: stationLat.value,
      lon: stationLon.value
    }
    await ApiService.updateEntity(RouteNamespace.stations, selectedStation.value['id'], payload)
  } catch (error) {
    console.error('Error updating station:', error)
  } finally {
    activeUpdateDialog.value = false
    toast.add({
      group: 'station-update',
      severity: 'success'
    })
    await getStations()
    loading.value = false
  }
}

const confirmUpdateLocation = async () => {
  try {
    const payload = {
      serialNumber: selectedData.value['serialNumber'],
      name: selectedData.value['name'],
      paidService: selectedData.value['paidService'],
      address: selectedData.value['address'],
      phone: selectedData.value['phone'],
      description: selectedData.value['description'],
      lat: selectedData.value['lat'],
      lon: selectedData.value['lon'],
      country: selectedData.value['country'],
      city: selectedData.value['city'],
      province: selectedData.value['province'],
      postcode: selectedData.value['postcode']
    }
    await ApiService.updateEntity(RouteNamespace.locations, selectedData.value['id'], payload)
  } catch (error) {
    console.error('Error updating station:', error)
  } finally {
    activeUpdateLocationDialog.value = false
    toast.add({
      group: 'station-update',
      severity: 'success',
      detail: t('detail.station.notifications.updateSuccess'),
      life: 3000
    })
    await getLocation()
    loading.value = false
  }
}

const resetForm = () => {
  numberConnections.value = 1
  alias.value = ''
}
const handleAddItem = () => {
  resetForm()
  activeCreationDialog.value = true
}
const actionsRemoveStation = async (id: string) => {
  try {
    loading.value = true
    await ApiService.deleteEntity(RouteNamespace.stations, id)
    toast.add({
      group: 'station-remove',
      severity: 'error',
      life: 3000,
      detail: t('detail.station.notifications.deleteSuccess')
    })
  } catch (error) {
    console.error('Error deleting station:', error)
  } finally {
    await getStations()
    loading.value = false
  }
}
const handleRemove = async (event: Event, id: string) => {
  event.stopPropagation()
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveStation(id)
      loading.value = false
    },
    undefined
  )
}

const filters = ref({
  active: { value: null, matchMode: FilterMatchMode.IN },
  'station.charger.name': { value: null, matchMode: FilterMatchMode.IN }
})

const initializeListStationStatus = () => {
  try {
    stations.value.forEach((st) => {
      const cpId = st.cpId
      if (cpId) {
        const status = statusNotificationStore.statusNotification.get(`${cpId}:0`)
        if (status) {
          allStationsStatus.value[cpId] = StationStatus[status.status.toUpperCase()]
        } else {
          allStationsStatus.value[cpId] = StationStatusMap.DISCONNECTED
        }
      }
    })
  } catch (err) {
    console.log('Not found stations')
  }
}

const getNewStationState = (newValue: StatusNotificationMapped) => {
  allStationsStatus.value[newValue.cpId] =
    StationStatus[newValue.status.toUpperCase()] || StationStatusMap.DISCONNECTED
}

const subscribeStatus = () => {
  statusNotificationStore.$subscribe((mutation) => {
    try {
      if (Array.isArray(mutation.events)) {
        mutation.events.forEach((event: DebuggerEvent) => {
          if (!event.newValue) return
          if (event.newValue.connectorId !== 0) return
          getNewStationState(event.newValue)
        })
      } else {
        if (!mutation.events.newValue) return
        if (mutation.events.newValue.connectorId !== 0) return
        getNewStationState(mutation.events.newValue)
      }
    } catch (err) {
      console.error('Failed to register the new connector status')
    }
  })
}

const subscribeMeterValues = () => meterValuesStore.$subscribe(() => setStationsSuppliedEnergy())

const getStats = async () => {
  try {
    stats.value = await ApiService.readAllEntities(
      `${RouteNamespace.locations}/${route.params.id}/${RouteNamespace.stats}`
    )
  } catch (error) {
    console.error('Error retrieving stats:', error)
  }
}

const handleDuration = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000) || 0
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return `${hours}h ${minutes}m ${seconds}s`
}

const getSuppliedEnergyByCpId = (cpId: string, numberConnections: number): number => {
  return Number(
    Array.from({ length: numberConnections }, (_, i) => {
      const energy = meterValuesStore.getSuppliedEnergy(cpId, i + 1)
      if (!energy) return 0
      return energy
    })
      .reduce((acc, item) => acc + item, 0)
      .toFixed(3)
  )
}

const setStationsSuppliedEnergy = () => {
  stations.value.forEach((station) =>
    stationsSuppliedEnergy.value.set(
      station.cpId,
      getSuppliedEnergyByCpId(station.cpId, station.numberConnections)
    )
  )
}

const onStationsPageChange = (event: PageState) => {
  currentStationsPage.value = event.page
  getStations()
}

const onPaymentsPageChange = (event: PageState) => {
  currentPaymentsPage.value = event.page
  getPayments()
}

onMounted(async () => {
  try {
    loading.value = true
    await Promise.all([getLocation(), getStations(), getRates(), getStats(), getPayments()])
    coords.value = []
    totalConnectors.value = stations.value.reduce((acc: number, item: Station) => {
      return acc + item['numberConnections']
    }, 0)
    setStationsSuppliedEnergy()
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  } finally {
    loading.value = false
    initializeListStationStatus()
    subscribeStatus()
    subscribeMeterValues()
  }
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-2rem text-3xl mb-3">
        <router-link :to="`/${RouteNamespace.locations}`">
          <Button class="text-500 hover:bg-teal-50" text>
            <div class="flex align-items-center">
              <i class="pi pi-arrow-left mr-2" />
              <span>{{ t('dashboard.locations') }}</span>
            </div>
          </Button>
        </router-link>
        <div class="flex flex-row ml-2 justify-content-between align-items-center mb-3">
          <div class="flex align-items-center">
            <svg-icon name="station" size="24" color="#626868" />
            <span class="font-bold mr-2">{{ t('dashboard.location') }}</span>
            <span class="font-family-light font-italic">{{
              capitalizeString(location['name'])
            }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.location.actions.update')"
            class="button button-normal shadow-1"
            rounded
            @click="handleUpdateLocation"
          >
            <template #icon>
              <svg-icon name="edit" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-column mt-5" />
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <Tabs v-model:value="tabIndex">
      <TabList>
        <Tab value="0">
          <div class="flex justify-content-between align-items-center max-w-10rem">
            <svg-icon name="vision" size="24" />
            <span>{{ t('detail.header.global') }}</span>
          </div>
        </Tab>
        <Tab value="1">
          <div class="flex justify-content-between align-items-center">
            <svg-icon name="station" size="24" />
            <span>{{ t('detail.header.chargePoints') }}</span>
            <Button
              v-tooltip.top="t('detail.station.actions.create')"
              rounded
              class="button button-normal ml-3"
              @click="handleAddItem"
            >
              <template #icon>
                <i class="font-bold pi pi-plus text-sm" />
              </template>
            </Button>
          </div>
        </Tab>
        <Tab value="2">
          <div class="flex justify-content-between align-items-center max-w-10rem">
            <svg-icon name="charge" size="24" />
            <span>{{ t('detail.header.payments') }}</span>
          </div>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <div class="flex flex-column justify-content-center h-5rem">
            <span class="font-bold">{{ t('detail.location.header.mensualStats') }}</span>
          </div>
          <div class="flex gap-2">
            <Card class="p-1 shadow-1 mb-2 col-2" style="background-color: #efefef">
              <template #title>
                <span class="text-sm">{{ t('detail.location.header.energySupplied') }}</span>
              </template>
              <template #content>
                <p class="text-3xl">{{ stats['energy'] || 0 }} {{ UnitOfMeasure.KWH }}</p>
              </template>
            </Card>
            <Card class="p-1 shadow-1 mb-2 col-2" style="background-color: #efefef">
              <template #title>
                <span class="text-sm">{{ t('detail.location.header.totalChargeTime') }}</span>
              </template>
              <template #content>
                <p class="text-3xl">{{ handleDuration(stats['duration']) || 0 }}</p>
              </template>
            </Card>
            <Card class="p-1 shadow-1 mb-2 col-2" style="background-color: #efefef">
              <template #title>
                <span class="text-sm">{{ t('detail.location.header.totalSessions') }}</span>
              </template>
              <template #content>
                <p class="text-2xl">{{ stats['transactions'] || 0 }}</p>
              </template>
            </Card>
            <Card class="p-1 shadow-1 mb-2 col-2" style="background-color: #efefef">
              <template #title>
                <span class="text-sm">{{ t('detail.location.header.totalIncome') }}</span>
              </template>
              <template #content>
                <p class="text-2xl">
                  {{ (Math.round(stats['cost'] * 100) / 100).toFixed(2) || 0 }} €
                </p>
              </template>
            </Card>
          </div>
        </TabPanel>
        <TabPanel value="1">
          <div class="flex">
            <div class="col-12">
              <DataTable
                v-model:filters="filters"
                :value="stations"
                :rows="rowsPerStationsPage"
                selectionMode="single"
                :rowHover="true"
                dataKey="id"
                @rowClick="handleDetail($event)"
                scrollable
              >
                <template #empty>
                  <BaseLottieAnimation :icon="clock" :label="t('detail.station.notFound')" />
                </template>
                <Column
                  field="station"
                  :header="t('detail.station.header.charger')"
                  header-class="font-bold"
                  class="table__charger"
                >
                  <template #body="slotProps">{{ slotProps.index + 1 }}</template>
                </Column>
                <Column
                  field="model"
                  :header="t('detail.station.header.model')"
                  header-class="font-bold"
                  class="table__model"
                >
                  <template #body="slotProps">
                    {{ `${capitalizeString(slotProps['data']['charger'].name)}` }}
                  </template>
                </Column>
                <Column
                  field="alias"
                  :header="t('detail.station.header.name')"
                  header-class="font-bold"
                  class="table__alias"
                >
                  <template #body="slotProps">
                    {{ `${capitalizeString(slotProps['data']['alias'])}` }}
                  </template>
                </Column>
                <Column
                  field="rate"
                  :header="t('detail.station.header.rate')"
                  header-class="font-bold"
                  class="table__rate"
                >
                  <template #body="slotProps">
                    {{ capitalizeString(slotProps['data']['rate']['alias']) }}
                  </template>
                </Column>
                <Column
                  field="numberConnections"
                  :header="t('detail.station.header.connector')"
                  header-class="table__header font-bold"
                  class="table__connectors"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Tag :value="slotProps['data']['numberConnections']" rounded />
                    </div>
                  </template>
                </Column>
                <Column
                  field="energy"
                  :header="t('detail.station.header.energySupplied')"
                  header-class="font-bold"
                  class="table__energy"
                >
                  <template #body="slotProps">
                    {{
                      `${stationsSuppliedEnergy.get(slotProps.data.cpId) || 0}  ${UnitOfMeasure.KWH}`
                    }}
                  </template>
                </Column>
                <Column
                  field="status"
                  :header="t('detail.station.header.status')"
                  header-class="font-bold"
                  class="table__status"
                >
                  <template #body="slotProps">
                    <BaseBadge
                      rounded
                      outlined
                      :style-header="`badgeStatus badgeStatus__${getClassForStatusStore(
                        chargerStatusesStore.chargerStatuses.get(slotProps.data.cpId) ===
                          ChargerWsStatus.CONNECTED
                          ? allStationsStatus[slotProps.data.cpId]
                          : StationStatusMap.DISCONNECTED
                      )}`"
                      :style-content="`status status__${getClassForStatusStore(
                        chargerStatusesStore.chargerStatuses.get(slotProps.data.cpId) ===
                          ChargerWsStatus.CONNECTED
                          ? allStationsStatus[slotProps.data.cpId]
                          : StationStatusMap.DISCONNECTED
                      )}`"
                      :content="
                        getStatusTranslationStore(
                          chargerStatusesStore.chargerStatuses.get(slotProps.data.cpId) ===
                            ChargerWsStatus.CONNECTED
                            ? allStationsStatus[slotProps.data.cpId]
                            : StationStatusMap.DISCONNECTED
                        )
                      "
                    />
                  </template>
                </Column>
                <Column
                  :header="t('detail.header.actions')"
                  header-class="table__header font-bold"
                  class="table__actions"
                >
                  <template #body="slotProps">
                    <div class="flex flex-row justify-content-center">
                      <Button
                        class="button button-normal mr-2"
                        v-tooltip.top="t('detail.station.actions.update')"
                        rounded
                        @click="handleUpdate($event, slotProps.data)"
                      >
                        <template #icon>
                          <svg-icon name="edit" size="20" color="#626868" />
                        </template>
                      </Button>
                      <ConfirmPopup />
                      <Button
                        class="button button-warning mr-2"
                        v-tooltip.top="t('detail.station.actions.reset')"
                        rounded
                        :disabled="
                          !(
                            chargerStatusesStore.chargerStatuses.get(slotProps.data.cpId) ===
                            ChargerWsStatus.CONNECTED
                          )
                        "
                        @click="confirmReset($event, slotProps.data.cpId)"
                      >
                        <template #icon>
                          <svg-icon name="restart" size="20" />
                        </template>
                      </Button>
                      <BaseConfirmDeletePopup ref="popup" />
                      <Button
                        class="button button-remove"
                        v-tooltip.top="t('detail.station.actions.delete')"
                        rounded
                        @click="handleRemove($event, slotProps.data.id)"
                      >
                        <template #icon>
                          <svg-icon name="trash" size="18" />
                        </template>
                      </Button>
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <Paginator
                    @page="onStationsPageChange"
                    class="flex justify-content-center"
                    :rows="rowsPerStationsPage"
                    :totalRecords="totalStations"
                    :pt="{
                      pageButton: ({ props, state, context }) => ({
                        class: context.active ? 'bg-gray-500 text-white' : undefined
                      })
                    }"
                  />
                </template>
              </DataTable>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div class="flex">
            <div class="col-12">
              <DataTable
                v-model:filters="filters"
                v-model:expanded-rows="expandedRows"
                v-model:selection="selectedProduct"
                :value="payments"
                ref="csv"
                editMode="row"
                :globalFilterFields="['contract.reference', 'active', 'cost']"
                :rows="10"
                dataKey="id"
                :exportFilename="`payments-${new Date().toLocaleDateString()}`"
                scrollable
              >
                <template #empty>
                  <BaseLottieAnimation :icon="clock" :label="t('detail.payments.notFound')" />
                </template>
                <Column expander class="table__expander" />
                <Column
                  field="cost"
                  :header="t('detail.payments.header.cost')"
                  header-class="font-bold"
                  class="table__cost"
                >
                  <template #body="slotProps">
                    {{ `${slotProps['data']['cost'].toFixed(2)} €` }}
                  </template>
                </Column>
                <Column
                  field="createdAt"
                  :header="t('detail.payments.header.start')"
                  header-class="font-bold"
                  class="table__start"
                >
                  <template #body="slotProps">
                    {{ new Date(slotProps['data']['createdAt']).toLocaleString() }}
                  </template>
                </Column>
                <Column
                  field="duration"
                  :header="t('detail.payments.header.duration')"
                  header-class="font-bold"
                  class="table__duration"
                >
                  <template #body="slotProps">
                    {{ handleDuration(slotProps['data']['duration']) }}
                  </template>
                </Column>
                <Column
                  field="energy"
                  :header="t('detail.payments.header.energySupplied')"
                  header-class="font-bold"
                  class="table__energy--payments"
                >
                  <template #body="slotProps">
                    {{ `${slotProps['data']['energy']} kWh` }}
                  </template>
                </Column>
                <Column
                  field="causeEnding"
                  :header="t('detail.payments.header.cause_ending')"
                  header-class="font-bold"
                  class="table__cause"
                >
                  <template #body="slotProps">
                    <div class="flex align-items-center">
                      <svg-icon
                        class="mr-2"
                        :name="getIconForCauseEnding(slotProps.data.causeEnding)"
                        size="24"
                        color="#E9E9E9"
                      />
                      {{ evaluateStatus(slotProps['data']['causeEnding']) }}
                    </div>
                  </template>
                </Column>
                <Column
                  :header="t('detail.payments.header.invoice')"
                  header-class="table__header font-bold"
                  class="table__actions--payments"
                >
                  <template #body>
                    <div class="flex flex-row justify-content-center">
                      <Button
                        v-tooltip.top="t('detail.payments.actions.download_invoice')"
                        disabled
                        rounded
                        class="button button-normal mr-2"
                      >
                        <template #icon>
                          <svg-icon name="invoice" size="16" />
                        </template>
                      </Button>
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <Paginator
                    @page="onPaymentsPageChange"
                    class="flex justify-content-center"
                    :rows="rowsPerPaymentsPage"
                    :totalRecords="totalPayments"
                    :pt="{
                      pageButton: ({ context }) => ({
                        class: context.active ? 'bg-gray-500 text-white' : undefined
                      })
                    }"
                  />
                </template>
                <template #expansion="slotProps">
                  <div class="flex flex-row p-1 h-2rem justify-content-evenly align-content-center">
                    <div class="flex flex-column">
                      <div class="flex">
                        <svg-icon name="contract" size="18" />
                        <span class="font-bold font-bold ml-2"
                          >{{ t('detail.payments.header.contract') }}:</span
                        >
                        <span class="ml-2">{{
                          slotProps?.data?.station?.customer?.contracts?.[0]?.reference.toUpperCase() ??
                          'N/A'
                        }}</span>
                      </div>
                    </div>
                    <div class="flex flex-column h-5rem justify-content-between">
                      <div class="flex">
                        <svg-icon name="reimbursable" color="#EA2839" size="18"></svg-icon>
                        <span class="font-bold ml-2">{{ t('detail.payments.header.refund') }}</span>
                        <span class="ml-2">{{ `${slotProps['data']['reimbursable']} €` }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </DataTable>
            </div>
          </div>
          <!--        </template>-->
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
  <BaseDialog
    v-model:visible="activeUpdateLocationDialog"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.location.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(stations['address']) }}
          </p>
        </div>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="location" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row">
        <div class="field col-12">
          <label for="name" class="font-family-light required">{{
            t('detail.customer.header.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="location-name" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              :placeholder="t('detail.station.dialog.placeholder.name')"
              v-model="selectedData['name']"
              id="name"
              type="text"
              :value="capitalizeString(selectedData['name'])"
            />
          </IconField>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-12">
          <span class="p-input-icon-left">
            <BaseGeoLocalization
              class="relative font-family-light"
              :address="selectedData['address']"
              @select-address="updateAddress"
              :placeholder="t('detail.customer.header.address')"
            />
          </span>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-12 md:col-6">
          <label class="font-family-light" for="city">{{ t('detail.customer.header.city') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="city" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="selectedData['city']"
              id="city"
              type="text"
              disabled
              :value="capitalizeString(selectedData['city'])"
              :pt="{
                root: { class: 'disabled-field' }
              }"
            />
          </IconField>
        </div>
        <div class="field col-6 md:col-6">
          <label class="font-family-light" for="province">{{
            t('detail.customer.header.province')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="province" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="selectedData['province']"
              id="province"
              type="text"
              disabled
              :pt="{
                root: { class: 'disabled-field' }
              }"
              :value="capitalizeString(selectedData['province'])"
            />
          </IconField>
        </div>
      </div>
      <div class="flex flex-row align-items-center justify-content-between">
        <div class="field col-12 md:col-6">
          <label class="font-family-light" for="postcode">{{
            t('detail.customer.header.postcode')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="postcode" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              class="mt-1"
              v-model="selectedData['postcode']"
              id="postcode"
              type="text"
              disabled
              :pt="{
                root: { class: 'disabled-field' }
              }"
            />
          </IconField>
        </div>
        <div class="field col-12 md:col-6">
          <label class="font-family-light" for="country">{{
            t('detail.customer.header.country')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="country" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="selectedData['country']"
              id="country"
              type="text"
              disabled
              :pt="{
                root: { class: 'disabled-field' }
              }"
              :value="capitalizeString(selectedData['country'])"
            />
          </IconField>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-12">
          <label class="font-family-light" for="description">{{
            t('detail.customer.header.description')
          }}</label>
          <div class="flex flex-row justify-content-start">
            <Textarea
              v-model="selectedData['description']"
              rows="5"
              cols="30"
              id="description"
              type="text"
              autoResize
              :placeholder="t('detail.location.dialog.placeholder.description')"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog :remove="!hidden" @cancel="handleCancel" @confirm="confirmUpdateLocation" />
      </div>
    </template>
  </BaseDialog>
  <BaseDialog
    v-model:visible="activeUpdateDialog"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.header.updateChargePoint') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(selectedData['name']) }}
          </p>
        </div>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="station-white" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-between">
          <div class="field mt-2 col-4">
            <label class="font-family-light required" for="name">{{
              t('detail.station.dialog.serialNumber')
            }}</label>
            <InputText
              v-model="selectedStation['serialNumber']"
              id="name"
              required="true"
              autofocus
              :placeholder="t('detail.station.dialog.placeholder.serialNumber')"
            />
          </div>
          <div class="field mt-2 col-5">
            <label class="font-family-light" for="name">{{
              t('detail.station.dialog.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="location-name" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="selectedStation['alias']"
                id="name"
                required="true"
                autofocus
                :placeholder="t('detail.station.dialog.placeholder.name')"
              />
            </IconField>
          </div>
          <div class="field col-3">
            <label class="font-family-light required" for="active">{{
              t('detail.station.dialog.paidService')
            }}</label>
            <ToggleSwitch v-model="selectedStation['paidService']" />
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field mb-3 col-4">
            <label class="font-family-light" for="name">{{
              t('detail.station.dialog.model')
            }}</label>
            <Select
              v-model="selectedStation['chargerId']"
              id="charger"
              required="required"
              optionValue="id"
              optionLabel="name"
              :placeholder="t('detail.station.dialog.placeholder.model')"
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundModel')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
              :options="
                chargers.map((charger: ChargerModel) => ({
                  ...charger,
                  name: capitalizeString(charger['name'])
                }))
              "
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
          </div>
          <div class="field mb-3 col-5">
            <label class="font-family-light" for="name">{{
              t('detail.station.dialog.rate')
            }}</label>
            <Select
              v-model="selectedStation['rateId']"
              id="rate"
              required="required"
              optionValue="id"
              optionLabel="alias"
              :placeholder="t('detail.station.dialog.placeholder.rate')"
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundRates')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
              :options="
                Array.isArray(rates[0])
                  ? rates[0].map((rate: Rate) => ({
                      ...rate,
                      alias: capitalizeString(rate['alias'])
                    }))
                  : []
              "
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
          </div>
          <div class="field mb-3 col-3">
            <label class="font-family-light" for="connector">{{
              t('detail.station.dialog.connector')
            }}</label>
            <BaseInputNumber v-model="selectedStation['numberConnections']" :min="0" :max="3" />
          </div>
        </div>
        <BaseMapContainer
          :coordsCharger="[stationLat, stationLon, stationStatus]"
          :zoom="16"
          incidence="3"
          :coords="coords"
          @update="updateCoords"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="confirmUpdateStation" :remove="!hidden" />
      </div>
    </template>
  </BaseDialog>
  <BaseDialog
    v-model:visible="activeCreationDialog"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">{{ t('detail.header.addChargePoint') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="station-white" size="24" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-between">
          <div class="field mt-2 col-4">
            <label class="font-family-light required" for="name">{{
              t('detail.station.dialog.serialNumber')
            }}</label>
            <InputText
              v-model="serialNumber"
              id="serialNumber"
              required="true"
              autofocus
              :placeholder="t('detail.station.dialog.placeholder.serialNumber')"
            />
          </div>
          <div class="field mt-2 col-5">
            <label class="font-family-light required" for="name">{{
              t('detail.station.dialog.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="location-name" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="alias"
                id="name"
                required="true"
                autofocus
                :placeholder="t('detail.station.dialog.placeholder.name')"
              />
            </IconField>
          </div>
          <div class="field col-3">
            <label class="font-family-light required" for="active">{{
              t('detail.station.dialog.paidService')
            }}</label>
            <ToggleSwitch v-model="paidService" />
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field mb-3 col-4">
            <label class="font-family-light required" for="name">{{
              t('detail.station.dialog.model')
            }}</label>
            <Select
              v-model="selectedCharger"
              id="charger"
              required="required"
              autofocus
              optionLabel="name"
              optionValue="id"
              :placeholder="t('detail.station.dialog.placeholder.model')"
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundModel')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-200'
                    : context.focused
                      ? 'bg-gray-200'
                      : undefined
                })
              }"
              :options="
                chargers.map((charger: ChargerModel) => ({
                  ...charger,
                  name: capitalizeString(charger['name'])
                }))
              "
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
          </div>
          <div class="field col-5">
            <label class="font-family-light required" for="name">{{
              t('detail.station.dialog.rate')
            }}</label>
            <Select
              v-model="selectedRate"
              id="rate"
              required="required"
              optionLabel="alias"
              optionValue="id"
              autofocus
              :placeholder="t('detail.station.dialog.placeholder.rate')"
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundRates')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-200'
                    : context.focused
                      ? 'bg-gray-200'
                      : undefined
                })
              }"
              :options="
                Array.isArray(rates[0])
                  ? rates[0].map((rate: Rate) => ({
                      ...rate,
                      alias: capitalizeString(rate['alias'])
                    }))
                  : []
              "
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
          </div>
          <div class="field col-3">
            <label class="font-family-light" for="connector">{{
              t('detail.station.dialog.connector')
            }}</label>
            <BaseInputNumber v-model="numberConnections" :min="1" :max="3" />
          </div>
        </div>
        <div class="field col-12">
          <BaseMapContainer
            :coordsLocation="[selectedData.lat, selectedData.lon]"
            :zoom="16"
            incidence="3"
            :coords="coords"
            @update="updateCoords"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="confirmInsert" :remove="!hidden" />
      </div>
    </template>
  </BaseDialog>
  <BasePushNotification
    :title="t('notifications.stationCreated')"
    icon-name="success"
    color="#00DB7F"
    group="stations"
    severity="success"
    :summary="t('notifications.stationCreatedSummary')"
  />
  <BasePushNotification
    :title="t('notifications.stationUpdated')"
    icon-name="success"
    color="#00DB7F"
    group="station-update"
    severity="success"
    :summary="t('notifications.stationUpdatedSummary')"
  />
  <BasePushNotification
    :title="t('notifications.stationRemoved')"
    icon-name="success"
    color="#EA2839"
    group="station-remove"
    severity="success"
    :summary="t('notifications.stationRemovedSummary')"
  />
</template>

<style scoped lang="scss">
::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

/* Stations table */

::v-deep(.table__charger) {
  width: 7%;
}

::v-deep(.table__model) {
  width: 10%;
}

::v-deep(.table__alias) {
  width: 16%;
}

::v-deep(.table__rate) {
  width: 13%;
}

::v-deep(.table__connectors) {
  width: 11%;
}

::v-deep(.table__energy) {
  width: 15%;
}

::v-deep(.table__status) {
  width: 15%;
}

::v-deep(.table__actions) {
  width: 13%;
}

/* Payments table */

::v-deep(.table__expander) {
  width: 6%;
}

::v-deep(.table__cost) {
  width: 8%;
}

::v-deep(.table__start) {
  width: 11%;
}

::v-deep(.table__duration) {
  width: 13%;
}

::v-deep(.table__energy--payments) {
  width: 20%;
}

::v-deep(.table__cause) {
  width: 32%;
}

::v-deep(.table__actions--payments) {
  width: 10%;
}
</style>
