<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { FilterMatchMode } from '@primevue/core/api'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { evaluateStatus } from '@/utils/status'
import { storeToRefs } from 'pinia'
import { useApi } from '@/stores/api/api'
import clock from '@/assets/lottie/clock.json'
import { capitalizeString } from '@/utils/capitalize'
import { useAuthStore } from '@/stores/auth'
import { useToast } from 'primevue/usetoast'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import { type Payment, TypeDocument } from '@/models/domain/payment/api/Payment'
import AppTopbar from '@/layout/AppTopbar.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'

const toast = useToast()
const { loading, payments } = storeToRefs(useApi())
const { name } = storeToRefs(useAuthStore())
const totalRecords = ref<number>()
const rowsPerPage = ref<number>(10)
const expandedRows = ref([])
const selectedProduct = ref()
const csv = ref()
const exportCSV = () => {
  csv.value.exportCSV()
}
const getPayments = async () => {
  try {
    payments.value = await ApiService.readAllEntities(RouteNamespace.payments)
    totalRecords.value = payments.value['length']
  } catch (error) {
    console.error('Error retrieving payments:', error)
  }
}
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const downloadReport = async (payment: Payment, type: TypeDocument) => {
  try {
    loading.value = true
    await ApiService.downloadEntity(
      `${RouteNamespace.files}/${type}/${payment['id']}`,
      payment['id']
    )
  } catch (error) {
    console.error('Error downloading payment report:', error)
    toast.add({
      severity: 'error',
      summary: t('detail.payment.notifications.errorDownload'),
      life: 5000,
      group: 'error-download'
    })
  } finally {
    loading.value = false
  }
}
const handleDuration = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return `${hours}h ${minutes}m ${seconds}s`
}
onMounted(async () => {
  loading.value = true
  await getPayments()
  loading.value = false
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex flex-row ml-2">
            <span class="font-bold mr-2">{{ t('dashboard.payments') }}</span>
            <div class="font-family-light font-italic text-3xl">{{ capitalizeString(name) }}</div>
          </div>
          <Button
            v-tooltip.top="t('detail.payments.actions.export_excel')"
            icon="pi pi-download"
            :label="t('detail.payments.actions.export')"
            class="button action-export"
            @click="exportCSV"
            rounded
            :pt="{
              icon: {
                class: ['text-xl']
              }
            }"
          />
        </div>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      v-model:expanded-rows="expandedRows"
      v-model:selection="selectedProduct"
      :value="payments"
      ref="csv"
      editMode="row"
      :globalFilterFields="['contract.reference', 'causeEnding', 'cost']"
      :rows="10"
      dataKey="id"
      :exportFilename="`payments-${new Date().toLocaleDateString()}`"
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.payments.notFound')" />
      </template>
      <Column expander style="width: 5rem" />
      <Column field="cost" :header="t('detail.payments.header.cost')" header-class="font-bold">
        <template #body="slotProps">
          {{ `${slotProps['data']['cost']} €` }}
        </template>
      </Column>
      <Column
        field="createdAt"
        :header="t('detail.payments.header.start')"
        header-class="font-bold"
      >
        <template #body="slotProps">
          {{ new Date(slotProps['data']['createdAt']).toLocaleString() }}
        </template>
      </Column>
      <Column
        field="duration"
        :header="t('detail.payments.header.duration')"
        header-class="font-bold"
      >
        <template #body="slotProps">
          {{ handleDuration(slotProps['data']['duration']) }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.payments.header.energySupplied')"
        header-class="font-bold"
      >
        <template #body="slotProps">
          {{ `${slotProps['data']['energy']} kWh` }}
        </template>
      </Column>
      <Column
        field="causeEnding"
        :header="t('detail.payments.header.cause_ending')"
        header-class="font-bold"
      >
        <template #body="slotProps">
          {{ evaluateStatus(slotProps['data']['causeEnding']) }}
        </template>
      </Column>
      <Column :header="t('detail.payments.header.invoice')" header-class="font-bold">
        <template #body="slotProps">
          <Button
            v-tooltip.top="t('detail.payments.actions.download_invoice')"
            rounded
            class="button button-normal mr-2"
            @click="downloadReport(slotProps.data, TypeDocument.INVOICES)"
          >
            <template #icon>
              <svg-icon name="invoice" size="16" color="#626868" />
            </template>
          </Button>
        </template>
      </Column>
      <template #footer>
        <Paginator
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="payments.length"
          :pt="{
            pageButton: ({ context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
      <template #expansion="slotProps">
        <div class="flex flex-row p-1 justify-content-around">
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="contract" size="18"></svg-icon>
              <span class="font-bold font-bold ml-2"
                >{{ t('detail.payments.header.contract') }}:</span
              >
              <span class="ml-2">{{
                slotProps?.data?.station?.customer?.contracts?.[0]?.reference.toUpperCase() ?? 'N/A'
              }}</span>
            </div>
          </div>
          <div class="flex flex-column h-2rem justify-content-between">
            <div class="flex">
              <svg-icon name="reimbursable" color="#EA2839" size="18"></svg-icon>
              <span class="font-bold ml-2">{{ t('detail.payments.header.refund') }}</span>
              <span class="ml-2">{{ `${slotProps['data']['reimbursable']} €` }}</span>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BasePushNotification
    :title="t('detail.payment.notifications.errorDownload')"
    icon-name="restart"
    color="#EA2839"
    group="error-download"
    severity="danger"
    :summary="t('detail.payment.notifications.errorDownload')"
  />
</template>
