<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { i18n, t } from '@/common/i18n'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import { useToast } from 'primevue/usetoast'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { type Customer } from '@/models'
import { useApi } from '@/stores/api/api'
import { storeToRefs } from 'pinia'
import { ApiService } from '@/services/ApiService'
import { evaluateRole } from '@/utils/status'
import { capitalizeString } from '@/utils/capitalize'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import router from '@/router'
import AppTopbar from '@/layout/AppTopbar.vue'

const apiStore = useApi()
const { loading, totalPayments, totalUsers, totalCards } = storeToRefs(useApi())

const defaultRole = ref('')
const popup = ref()
const hidden = ref<boolean>(true)
const mailActivation = ref<boolean>(true)
const uploadedImage = ref('')
const previewImage = ref('')
const customer = {
  name: ref<string>(''),
  description: ref<string>(''),
  province: ref<string>(''),
  city: ref<string>(''),
  country: ref<string>(''),
  identityNumber: ref<string>(''),
  imageProfile: ref<string>(''),
  postcode: ref<string>(''),
  address: ref<string>(''),
  phone: ref<string>(''),
  email: ref<string>('')
}
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const defaultSelectedData: Customer = {
  address: '',
  city: '',
  country: '',
  description: '',
  identityNumber: '',
  imageProfile: '',
  email: '',
  phone: '',
  postcode: '',
  province: '',
  role: '',
  name: '',
  surname: ''
}
const selectedData = ref<Customer>(defaultSelectedData)
const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const customers = ref([])
const roles = ref([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const toast = useToast()

const getCustomers = async () => {
  try {
    const customersAndTotal = await ApiService.readAllEntities(
      `${RouteNamespace.customers}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    customers.value = customersAndTotal.customers
    totalRecords.value = customersAndTotal.total
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
const resetModal = () => {
  Object.values(customer).forEach((field) => {
    field.value = ''
  })
}
const handleCreate = () => {
  activeCreationDialog.value = true
  resetModal()
}

const handleCancel = () => {
  activeUpdateDialog.value = false
  activeCreationDialog.value = false
  //remove query param
  router.push({ name: RouteNamespace.customers })
}
const confirmInsert = async () => {
  try {
    loading.value = true
    const payload = {
      name: customer.name.value,
      description: customer.description.value,
      identityNumber: customer.identityNumber.value,
      province: customer.province.value,
      city: customer.city.value,
      country: customer.country.value,
      postcode: customer.postcode.value,
      address: customer.address.value,
      phone: customer.phone.value,
      email: customer.email.value,
      role: selectedData.value.role
    }
    await ApiService.createEntity(RouteNamespace.customers, payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    activeCreationDialog.value = false
    await getCustomers()
    toast.add({
      life: 3000,
      severity: 'success',
      detail: i18n.global.t('detail.customer.notifications.createSuccess')
    })
  }
}
const handleUpdate = async (row: Customer) => {
  selectedData.value = row
  activeUpdateDialog.value = true
}
const handleRefresh = async (row: Customer) => {
  try {
    console.log('refreshing customer', row.name)
  } catch (error) {
    console.error('Error occurred refreshing customer:', error)
  }
}
const handleChange = (event: { value: string }) => {
  defaultRole.value = event.value
}
const confirmUpdate = async () => {
  try {
    loading.value = true
    const payload = {
      name: selectedData.value.name,
      description: selectedData.value.description,
      identityNumber: selectedData.value.identityNumber,
      province: selectedData.value.province,
      city: selectedData.value.city,
      country: selectedData.value.country,
      postcode: selectedData.value.postcode,
      address: selectedData.value.address,
      phone: selectedData.value.phone,
      email: selectedData.value.email,
      role: selectedData.value.role
    }
    await ApiService.updateEntity(RouteNamespace.customers, selectedData.value['id'], payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toast.add({
      life: 3000,
      group: 'customer-update-error',
      severity: 'error',
      detail: i18n.global.t('detail.customer.notifications.updateSuccessError')
    })
  } finally {
    activeUpdateDialog.value = false
    toast.add({
      life: 3000,
      group: 'customer-update',
      severity: 'success',
      detail: i18n.global.t('detail.customer.notifications.updateSuccess')
    })
    await getCustomers()
  }
}
const actionsRemoveCustomer = async (row: string) => {
  try {
    await ApiService.deleteEntity(RouteNamespace.customers, row['id'])
    toast.add({
      life: 3000,
      severity: 'success',
      detail: i18n.global.t('detail.customer.notifications.deleteSuccess')
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getCustomers()
  }
}
const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveCustomer(row)
      loading.value = false
    },
    undefined
  )
}
const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getCustomers()
}
onMounted(async () => {
  try {
    loading.value = true
    await getCustomers()
    roles.value = apiStore.roles
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="customer" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.customers') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.customer.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="customers"
      :globalFilterFields="['name', 'surname', 'city', 'email', 'country', 'role']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.customer.notFound')" />
      </template>
      <Column field="name" :header="t('detail.customer.header.name')" class="table__name">
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
          {{ capitalizeString(slotProps.data['surname']) }}
        </template>
      </Column>
      <Column
        field="role"
        :header="t('detail.customer.header.role')"
        header-class="font-bold"
        class="table__role"
      >
        <template #body="slotProps">
          {{ evaluateRole(slotProps.data['role']) }}
        </template>
      </Column>
      <Column
        field="billing"
        :header="t('detail.customer.header.billing')"
        header-class="font-bold"
        class="table__billing"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['totalPayments']?.toFixed(2)} €` }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.customer.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${(slotProps.data['totalEnergy'] / 1000).toFixed(2)} kWh` }}
        </template>
      </Column>
      <Column
        field="users"
        :header="t('detail.customer.header.users')"
        header-class="font-bold"
        class="table__users"
      >
        <template #body>
          {{ totalUsers }}
        </template>
      </Column>
      <Column
        field="cards"
        :header="t('detail.customer.header.rfidCards')"
        header-class="font-bold"
        class="table__cards"
      >
        <template #body>
          {{ totalCards }}
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.customer.actions.update')"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="16" color="#626868" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.customer.actions.activation')"
              rounded
              class="button button-warning mr-2"
              @click="handleRefresh(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="mail" size="16" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-tooltip.top="t('detail.customer.actions.delete')"
              icon="pi pi-trash"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          :pt="{
            pageButton: ({ context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
    <BaseDialog
      v-model:visible="activeUpdateDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
          <div class="flex flex-row">
            <p class="p-dialog-title mr-1">{{ t('detail.customer.actions.update') }} -</p>
            <p class="p-dialog-title font-family-light font-italic">
              {{ capitalizeString(selectedData['name']) }}
            </p>
          </div>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mr-3">
          <svg-icon name="customer" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="name">{{
              t('detail.customer.header.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="customer" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.name')"
                class="border-400"
                v-model="selectedData['name']"
                id="name"
                type="text"
                :value="capitalizeString(selectedData['name']) || ''"
              />
            </IconField>
          </div>
          <div class="field col-3">
            <label class="font-family-light" for="identityNumber">{{
              t('detail.customer.header.identityNumber')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="nif" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="selectedData['identityNumber']"
                class="border-400"
                id="identityNumber"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-3">
            <label class="font-family-light" for="role">{{
              t('detail.customer.header.role')
            }}</label>
            <div class="flex flex-row align-items-center">
              <Select
                class="w-12 h-3rem align-items-center"
                v-model="selectedData.role"
                optionLabel="role"
                optionValue="role"
                :options="roles"
                :value="defaultRole"
                @change="handleChange"
                :placeholder="t('detail.customer.dialog.placeholder.role')"
                :emptyMessage="t('detail.customer.dialog.placeholder.notFoundRoles')"
                :pt="{
                  item: ({ props, state, context }) => ({
                    class: context.selected
                      ? 'bg-gray-300'
                      : context.focused
                        ? 'bg-gray-100'
                        : undefined
                  })
                }"
              >
                <template #dropdownicon>
                  <div class="flex flex-column justify-content-center p-0 col-12">
                    <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                  </div>
                </template>
              </Select>
            </div>
          </div>
        </div>
        <div class="flex flex-row p-0 col-12">
          <div class="field col-12">
            <label class="font-family-light" for="address">{{
              t('detail.customer.header.address')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="address" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                class="border-400"
                v-model="selectedData['address']"
                id="address"
                type="text"
                :value="capitalizeString(selectedData['address']) || ''"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="city">{{
              t('detail.customer.header.city')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="city" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.city')"
                class="border-400"
                v-model="selectedData['city']"
                id="city"
                type="text"
                :value="capitalizeString(selectedData['city']) || ''"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="province">{{
              t('detail.customer.header.province')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="province" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.province')"
                class="border-400"
                v-model="selectedData['province']"
                id="province"
                type="text"
                :value="capitalizeString(selectedData['province']) || ''"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="postcode">{{
              t('detail.customer.header.postcode')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="postcode" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.postcode')"
                class="border-400"
                v-model="selectedData['postcode']"
                id="postcode"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="country">{{
              t('detail.customer.header.country')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="country" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.country')"
                class="border-400"
                v-model="selectedData['country']"
                id="country"
                type="text"
                :value="capitalizeString(selectedData['country']) || ''"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="email">{{
              t('detail.customer.header.email')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="mail" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.email')"
                class="border-400"
                v-model="selectedData['email']"
                id="email"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="phone">{{
              t('detail.customer.header.phone')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="phone" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.phone')"
                class="border-400"
                v-model="selectedData['phone']"
                id="phone"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-6">
            <label class="font-family-light" for="description">{{
              t('detail.customer.header.description')
            }}</label>
            <Textarea
              v-model="selectedData['description']"
              id="description"
              rows="5"
              cols="30"
              type="text"
              autoResize
              :placeholder="t('detail.customer.dialog.placeholder.description')"
            />
          </div>
          <div class="flex flex-column field col-6">
            <label class="font-family-light" for="upload">{{
              t('detail.customer.actions.upload')
            }}</label>
            <div class="flex flex-row justify-content-between align-content-center">
              <Avatar :image="uploadedImage" class="mr-2" size="xlarge" shape="circle" />
              <FileUpload
                class="w-fit"
                ref="fileupload"
                mode="basic"
                name="demo[]"
                url="/api/upload"
                accept="image/*"
                :maxFileSize="1000000"
                :chooseLabel="t('detail.customer.actions.upload')"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmUpdate" :remove="hidden" />
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      v-model:visible="activeCreationDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.customer.actions.create') }}</p>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mr-3">
          <svg-icon name="customer" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="col-6">
            <label class="font-family-light" for="name">{{
              t('detail.customer.header.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="customer" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="customer.name.value"
                :placeholder="t('detail.customer.dialog.placeholder.name')"
                class="border-400"
                id="name"
                type="text"
              />
            </IconField>
          </div>
          <div class="col-3">
            <label class="font-family-light" for="identityNumber">{{
              t('detail.customer.header.identityNumber')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="nif" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="customer.identityNumber.value"
                class="border-400"
                id="identityNumber"
                type="text"
              />
            </IconField>
          </div>
          <div class="col-3">
            <label class="font-family-light" for="role">{{
              t('detail.customer.header.role')
            }}</label>
            <Select
              class="w-12 h-3rem align-items-center"
              v-model="selectedData.role"
              optionLabel="role"
              optionValue="role"
              :options="roles"
              :value="defaultRole"
              :placeholder="t('detail.customer.dialog.placeholder.role')"
              :emptyMessage="t('detail.customer.dialog.placeholder.notFoundRoles')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
          </div>
        </div>
        <div class="flex flex-row align-items-center">
          <div class="field col-12">
            <label class="font-family-light" for="address">{{
              t('detail.customer.header.address')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="address" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                class="border-400"
                v-model="customer.address.value"
                id="address"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="city">{{
              t('detail.customer.header.city')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="city" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.city')"
                class="border-400"
                v-model="customer.city.value"
                id="city"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="province">{{
              t('detail.customer.header.province')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="province" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.province')"
                class="border-400"
                v-model="customer.province.value"
                id="province"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label class="font-family-light" for="postcode">{{
              t('detail.customer.header.postcode')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="postcode" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.postcode')"
                class="border-400"
                v-model="customer.postcode.value"
                id="postcode"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="country">{{
              t('detail.customer.header.country')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="country" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.country')"
                class="border-400"
                v-model="customer.country.value"
                id="country"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label class="font-family-light" for="email">{{
              t('detail.customer.header.email')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="mail" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.email')"
                class="border-400"
                v-model="customer.email.value"
                id="email"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="phone">{{
              t('detail.customer.header.phone')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="phone" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.customer.dialog.placeholder.phone')"
                class="border-400"
                v-model="customer.phone.value"
                id="phone"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-6">
            <label class="font-family-light" for="description">{{
              t('detail.customer.header.description')
            }}</label>
            <Textarea
              v-model="customer.description.value"
              id="description"
              rows="5"
              cols="30"
              type="text"
              autoResize
              :placeholder="t('detail.customer.dialog.placeholder.description')"
            />
          </div>
          <div class="flex flex-column field col-6">
            <label class="font-family-light" for="upload">{{
              t('detail.customer.actions.upload')
            }}</label>
            <Avatar :image="previewImage" class="mr-2" size="xlarge" shape="circle" />
            <FileUpload
              class="w-fit"
              ref="fileupload"
              mode="basic"
              name="demo[]"
              url="/api/upload"
              accept="image/*"
              :maxFileSize="1000000"
              :chooseLabel="t('detail.customer.actions.upload')"
            />
          </div>
        </div>
        <div class="flex flex-row field col-5">
          <Checkbox v-model="mailActivation" :binary="true" />
          <label for="ingredient1" class="ml-2"> Enviar mail de activación </label>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmInsert" :remove="hidden" />
        </div>
      </template>
    </BaseDialog>
  </div>
  <BasePushNotification
    :title="t('notifications.customerUpdatedSuccessfully')"
    icon-name="success"
    color="#00DB7F"
    group="customer-update"
    severity="success"
    :summary="t('notifications.customerUpdatedError')"
  />
  <BasePushNotification
    :title="t('notifications.customerUpdatedError')"
    icon-name="error"
    color="#EA2839"
    group="customer-update-error"
    severity="danger"
    :summary="t('notifications.customerUpdatedError')"
  />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 16%;
}

::v-deep(.table__role) {
  width: 14%;
}

::v-deep(.table__billing) {
  width: 14%;
}

::v-deep(.table__energy) {
  width: 16%;
}

::v-deep(.table__users) {
  width: 11%;
}

::v-deep(.table__cards) {
  width: 13%;
}

::v-deep(.table__actions) {
  width: 16%;
}
</style>
