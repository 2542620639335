<script setup lang="ts">
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete'
import { onMounted, ref } from 'vue'
import { t } from '@/common/i18n'

const autocompleteRef = ref<GeocoderAutocomplete | null>(null)
const props = defineProps<{
  placeholder: string
  address?: string
}>()
const emits = defineEmits(['select-address', 'close'])

const initialConfig = () => {
  const apiKey = import.meta.env.VITE_GEOAPIFY
  const options = {
    skipDetails: true,
    debounceDelay: 300,
    placeholder: props.placeholder || ' ',
    value: props.address,
    countrycodes: ['es', 'fr', 'en']
    /* Geocoder options */
  }
  const item = document.getElementById('autocomplete')
  if (item) {
    autocompleteRef.value = new GeocoderAutocomplete(item, apiKey, options)
    autocompleteRef.value.on('select', (location) => {
      if (location) {
        emits('select-address', location['properties'])
      } else emits('close', null)
    })
    autocompleteRef.value.setValue(props.address || '')
  }
}
onMounted(() => {
  initialConfig()
})
</script>

<template>
  <div id="autocomplete" class="relative">
    <div class="flex flex-row">
      <svg-icon name="address" size="18" class="mr-2" color="#9E9E9E" />
      <label for="address" class="required">{{ t('detail.customer.header.address') }}</label>
    </div>
  </div>
</template>

<style lang="scss">
.geoapify-autocomplete-input {
  margin-top: 0.5em;
  width: 100% !important;
  height: 41px !important;
  border-color: var(--midGray) !important;
}
</style>
