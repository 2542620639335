export interface Cluster {
  getChildCount(): number
}

export const STATES = {
  available: 'available' as const,
  unavailable: 'unavailable' as const,
  suspended: 'suspended' as const,
  disconnected: 'disconnected' as const
}
export type State = keyof typeof STATES

export const COLORS: Record<State, string> = {
  [STATES.available]: '#00DB7F',
  [STATES.unavailable]: '#FB1626',
  [STATES.suspended]: '#FFC107',
  [STATES.disconnected]: '#626868'
}

export const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'

export interface LocationState {
  cpAvailables: number
  cpUnavailables: number
  cpIncidences: number
  cpDisconnected: number
}
