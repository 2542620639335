import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Aura from '@primevue/themes/aura'

import Vue3Lottie from 'vue3-lottie'
import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import OverlayBadge from 'primevue/overlaybadge'
import Button from 'primevue/button'
import Card from 'primevue/card'
import LWChart from '@/components/ui/BaseChart.vue'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Column from 'primevue/column'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import Dialog from 'primevue/dialog'
import DialogService from 'primevue/dialogservice'
import Drawer from 'primevue/drawer'
import Select from 'primevue/select'
import FileUpload from 'primevue/fileupload'
import Fluid from 'primevue/fluid'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import Inplace from 'primevue/inplace'
import InputMask from 'primevue/inputmask'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Image from 'primevue/image'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import Popover from 'primevue/popover'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import Password from 'primevue/password'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import SelectButton from 'primevue/selectbutton'
import SplitButton from 'primevue/splitbutton'
import StyleClass from 'primevue/styleclass'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import Tag from 'primevue/tag'
import Tooltip from 'primevue/tooltip'
import ToggleButton from 'primevue/togglebutton'
import Tab from 'primevue/tab'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import TabMenu from 'primevue/tabmenu'
import DatePicker from 'primevue/datepicker'
import ToggleSwitch from 'primevue/toggleswitch'
import Tree from 'primevue/tree'
import '@/assets/styles.scss'
import { dateFormat, dayNames, i18n, monthNames, status, t } from '@/common/i18n'
import svgIconLoader from '@/plugins/svg-icon-loader'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import TabPanel from 'primevue/tabpanel'
import TabPanels from 'primevue/tabpanels'

// import * as Sentry from '@sentry/vue'
const app = createApp(App)
// SENTRY PENDING INTEGRATION
/*Sentry.init({
  app,
  dsn: 'https://0a518a3329c587d94f10d443ef615417@o1423073.ingest.sentry.io/4506183396425728',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})*/
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(router)
app.use(pinia)
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      cssLayer: false,
      darkModeSelector: '.dark'
    }
  },
  locale: {
    clear: t('actions.delete'),
    dayNames,
    dayNamesShort: dayNames,
    dayNamesMin: dayNames,
    monthNames,
    monthNamesShort: monthNames,
    today: t('locale.today'),
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat,
    noFileChosenMessage: '',
    pending: status.pending
  }
})
app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)

app.use(i18n)
app.use(svgIconLoader)
app.use(Vue3Lottie)

app.directive('tooltip', Tooltip)
app.directive('overlaybadge', OverlayBadge)
app.directive('styleclass', StyleClass)

app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
app.component('Button', Button)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('Column', Column)
app.component('ConfirmPopup', ConfirmPopup)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('DatePicker', DatePicker)
app.component('Drawer', Drawer)
app.component('Select', Select)
app.component('Dialog', Dialog)
app.component('FileUpload', FileUpload)
app.component('Fluid', Fluid)
app.component('LWChart', LWChart)
app.component('IconField', IconField)
app.component('Inplace', Inplace)
app.component('InputIcon', InputIcon)
app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('Image', Image)
app.component('Menu', Menu)
app.component('Menubar', Menubar)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('Paginator', Paginator)
app.component('Panel', Panel)
app.component('Password', Password)
app.component('Popover', Popover)
app.component('ProgressBar', ProgressBar)
app.component('ProgressSpinner', ProgressSpinner)
app.component('SelectButton', SelectButton)
app.component('SplitButton', SplitButton)
app.component('TabMenu', TabMenu)
app.component('Tab', Tab)
app.component('Tabs', Tabs)
app.component('TabPanels', TabPanels)
app.component('TabPanel', TabPanel)
app.component('TabList', TabList)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('ToggleButton', ToggleButton)
app.component('ToggleSwitch', ToggleSwitch)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('Tree', Tree)
app.component('SvgIcon', SvgIcon)

app.mount('#app')
