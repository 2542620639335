import type { AxiosError } from 'axios'
import axios from 'axios'

export const tokenType = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken'
}

type ErrorResponse = AxiosError<unknown>
export const getToken = (tokenType: string) => {
  const token = sessionStorage.getItem(tokenType)
  return token ? `Bearer ${token}` : undefined
}

export const axiosInstance = (optionToken: string) => {
  const instance = axios.create({
    baseURL: `${import.meta.env.VITE_REST_API}${import.meta.env.VITE_API_VERSION}`,
    timeout: 10000
  })

  instance.interceptors.request.use((config) => {
    config.headers.authorization = getToken(optionToken)
    return config
  })
  return instance
}

const instance = axiosInstance(tokenType.ACCESS_TOKEN)
const handleErrorResponse = async (error: ErrorResponse) => {
  try {
    if (!error.response) console.log('Please check your internet connection.')
  } catch (e) {
    console.log(e)
  }
}

instance.interceptors.response.use((response) => response, handleErrorResponse)

export default instance
