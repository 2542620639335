import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { Payment } from '@/models'

export const usePayments = defineStore('payments', {
  state: () => ({
    payments: [] as Payment[]
  }),
  actions: {
    bindEvents() {
      socket.on('new-payment', (payment: Payment) => {
        this.addPayment(payment)
      })
    },
    addPayment(payment: Payment) {
      this.payments.push(payment)
    },
    setPayments(payments: Payment[]) {
      this.payments = payments
    }
  }
})
