<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from 'vue'
import L, { type Map } from 'leaflet'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster'
import type { CoordinateLocation, PositionEditableLocations } from '@/models/ui/Position'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { capitalizeString } from '@/utils/capitalize'
import type { Cluster, State } from '@/models/ui/Map'
import { attribution, COLORS, STATES } from '@/models/ui/Map'
import { StationStatus } from '@/models/ocpp/enums'
import { StationStatusMap } from '@/models/domain/location/enums'
import { useRouter } from 'vue-router'
import type { StationsParams } from '@/models'
import type { ListStationsStatus } from '@/models/domain/station/component/ListStationsStatus'

const router = useRouter()
const props = defineProps<PositionEditableLocations>()
const { coords, stations, zoom, isDraggable, stationsStatus } = toRefs(props)
const mapElement = ref<HTMLElement | null>(null)
let leafletMap: Map | undefined
const leafletControl = new L.Control({
  position: 'bottomright'
})
const tiles = L.tileLayer(import.meta.env.VITE_OPENSTREETMAP, {
  maxZoom: 19,
  attribution
})

const latLng = L.latLng(41, 0)
const getSvgIcon = (color: string) => {
  return `<?xml version="1.0"?>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,2.01C8.14,2.01,5.01,5.15,5.01,9c0,3.957,4.395,10.373,6.215,12.631c0.4,0.496,1.151,0.496,1.55,0 C14.595,19.373,18.99,12.957,18.99,9C18.99,5.15,15.86,2.01,12,2.01z M11,17v-5H9l4-7v5h2L11,17z"
            fill="${color}" stroke="${color}" stroke-width="0"/>
  </svg>`
}

const clusterIconCreate = (cluster: Cluster) => {
  return new L.DivIcon({
    html: `<div><span>${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster',
    iconSize: new L.Point(40, 40)
  })
}
const markers = L.markerClusterGroup({
  iconCreateFunction: clusterIconCreate,
  chunkedLoading: true,
  spiderfyOnMaxZoom: false,
  disableClusteringAtZoom: 18,
  showCoverageOnHover: false,
  maxClusterRadius: 50,
  spiderLegPolylineOptions: {
    opacity: 0
  }
})

const iconColor = (state: string) => {
  const color = COLORS[state]
  return L.divIcon({
    className: 'marker',
    html: getSvgIcon(color),
    iconSize: [28, 28],
    iconAnchor: [12, 24],
    popupAnchor: [0, -26]
  })
}

const calculateTotalByStatus = (
  values: ListStationsStatus[],
  status: StationStatusMap | StationStatus
) => {
  return values.filter((value) => Object.values(value)[0] === status).length
}

const numberTotalStations = () => {
  let total = 0
  if (stations.value)
    stations.value.forEach((station) => {
      total += station[1].length
    })
  return total
}

const getStatusesTotalsPopup = (totalStations: number, stations: ListStationsStatus[]) => {
  const count = {
    [StationStatus.AVAILABLE]: 0,
    [StationStatus.FAULTED]: 0,
    [StationStatus.UNAVAILABLE]: 0,
    [StationStatusMap.DISCONNECTED]: 0
  }
  count[StationStatus.AVAILABLE] += calculateTotalByStatus(
    Object.values(stations),
    StationStatus.AVAILABLE
  )
  count[StationStatus.UNAVAILABLE] += calculateTotalByStatus(
    Object.values(stations),
    StationStatus.UNAVAILABLE
  )
  count[StationStatus.FAULTED] += calculateTotalByStatus(
    Object.values(stations),
    StationStatus.FAULTED
  )
  count[StationStatusMap.DISCONNECTED] =
    totalStations -
    count[StationStatus.FAULTED] -
    count[StationStatus.UNAVAILABLE] -
    count[StationStatus.AVAILABLE]
  return count
}

const getStatusesTotalsLegend = () => {
  const count = {
    [StationStatus.AVAILABLE]: 0,
    [StationStatus.FAULTED]: 0,
    [StationStatus.UNAVAILABLE]: 0,
    [StationStatusMap.DISCONNECTED]: 0
  }
  for (const location in stationsStatus.value) {
    count[StationStatus.AVAILABLE] += calculateTotalByStatus(
      Object.values(stationsStatus.value[location]),
      StationStatus.AVAILABLE
    )
    count[StationStatus.UNAVAILABLE] += calculateTotalByStatus(
      Object.values(stationsStatus.value[location]),
      StationStatus.UNAVAILABLE
    )
    count[StationStatus.FAULTED] += calculateTotalByStatus(
      Object.values(stationsStatus.value[location]),
      StationStatus.FAULTED
    )
  }
  count[StationStatusMap.DISCONNECTED] =
    numberTotalStations() -
    count[StationStatus.FAULTED] -
    count[StationStatus.UNAVAILABLE] -
    count[StationStatus.AVAILABLE]
  return count
}

const htmlPopup = (popupContent: string, id: string, state: string) => {
  let countedStates = {
    [StationStatus.AVAILABLE]: 0,
    [StationStatus.FAULTED]: 0,
    [StationStatus.UNAVAILABLE]: 0,
    [StationStatusMap.DISCONNECTED]: 0
  }
  const matchingLocation = stations.value
    ? stations.value.find((location: StationsParams) => location[0] === id)
    : undefined
  const numbersCPLocation = matchingLocation ? matchingLocation[1].length : 0
  if (stationsStatus.value) {
    const stations = stationsStatus.value[id]
    if (stations) {
      countedStates = getStatusesTotalsPopup(numbersCPLocation, stations)
    }
  }
  const div = L.DomUtil.create('div', 'text-gray-600')
  div.innerHTML = `
      <div class="flex flex-column align-items-center">
          <div class="flex flex-row">
              <div class="status status__${state}"></div>
              <span class="font-bold ml-2">${capitalizeString(popupContent)}</span>
          </div>
      </div>
          <div class="p-2">
          <div class="flex flex-row">
              <span>${numbersCPLocation} ${t('chargePoints')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatusMap.DISCONNECTED]} ${t('status.disconnected')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.AVAILABLE]} ${t('status.available')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.UNAVAILABLE]} ${t('status.unavailable')}</span>
          </div>
          <div class="flex flex-row">
              <span>${countedStates[StationStatus.FAULTED]} ${t('status.incidence')}</span>
          </div>
      </div>
  `
  div.addEventListener('click', () => {
    router.push({ path: `${RouteNamespace.locations}/${id}` })
  })
  return div
}

const createLegendRow = (color: string, label: string, count: number) => `
  <div class="w-11rem pl-3 pr-3 text-gray-700">
      <div class="flex flex-row justify-content-between p-1 mb-1">
        <div class="flex flex-row align-items-center mt-2 mb-1">
          <i class="status status__${color}"></i>
          <span class="font-bold ml-2">${label}</span>
        </div>
        <div class="mt-2">${count}</div>
      </div>
  </div>`

const clickPan = (e: L.LeafletEvent) => {
  if (leafletMap && e.target) leafletMap.panTo((e.target as L.Marker).getLatLng())
}

const createHTMLLegend = () => {
  const color = [STATES.available, STATES.unavailable, STATES.suspended, STATES.disconnected]
  const label = [
    `${t('status.available')}`,
    `${t('status.unavailable')}`,
    `${t('status.incidence')}`,
    `${t('status.disconnected')}`
  ]
  const countedStatus = getStatusesTotalsLegend()
  const legendRows = color
    .map((condition, i) => {
      let statusCount = 0
      if (condition === STATES.available) {
        statusCount = countedStatus[StationStatus.AVAILABLE]
      } else if (condition === STATES.unavailable) {
        statusCount = countedStatus[StationStatus.UNAVAILABLE]
      } else if (condition === STATES.suspended) {
        statusCount = countedStatus[StationStatus.FAULTED]
      } else if (condition === STATES.disconnected) {
        statusCount = countedStatus[StationStatusMap.DISCONNECTED]
      }
      return createLegendRow(condition, label[i], statusCount)
    })
    .join('')
  return `<div class="bg-white border-round-xl">${legendRows}</div>`
}

const changeLegend = () => {
  const legend = leafletControl.getContainer()
  if (legend) legend.innerHTML = createHTMLLegend()
}

const getColorState = (stations: ListStationsStatus[]) => {
  let colorState: State = STATES.disconnected
  stations.every((station) => {
    const state = Object.values(station)[0].toLowerCase()
    if (state === STATES.available) {
      colorState = STATES.available
      return false
    } else if (state === STATES.unavailable) {
      if (colorState !== STATES.available) colorState = STATES.unavailable
    } else if (state === STATES.suspended) {
      if (colorState !== STATES.disconnected) colorState = STATES.suspended
    }
    return true
  })
  return colorState
}

const createNewsHTMLPopups = () => {
  let state = STATES.disconnected
  markers.clearLayers()
  if (Array.isArray(coords.value)) {
    coords.value.forEach((item: CoordinateLocation) => {
      const [id, popupContent, latitude, longitude] = item
      if (stationsStatus.value) {
        const stations = stationsStatus.value[id]
        if (stations) {
          state = getColorState(stations)
        }
      }
      const icon = iconColor(state)
      const marker = L.marker([latitude, longitude], { icon, draggable: isDraggable.value })
        .bindPopup(htmlPopup(popupContent, id, state))
        .on('click', clickPan)
      markers.addLayer(marker)
    })
  }
}

const updateMap = () => {
  if (leafletMap) {
    leafletMap.eachLayer((layer) => leafletMap?.removeLayer(layer))
    leafletMap.addLayer(tiles)
    const div = L.DomUtil.create('div', 'legend')
    leafletControl.onAdd = () => {
      div.innerHTML = createHTMLLegend()
      return div
    }
    leafletControl.addTo(leafletMap)
    createNewsHTMLPopups()
    leafletMap.addLayer(markers)
  }
}

const initMap = (element: HTMLElement) => {
  leafletMap?.remove()
  const map = L.map(element, { center: latLng, zoom: zoom.value })
  leafletMap = map
  updateMap()
  return map
}

/**
 * TODO
 * Cuando se desconecta un cargador y vuelve a conectar cualquier estado que tuviera antes de la desconexión, lo ignora y
 * le pone available --> El cargador esta mandando mal el estado del conector 0, lo manda como available
 * En el nodo Save variables de OCPP (en la parte de BootNotification)
 */

watch(
  [coords, stations],
  () => {
    if (mapElement.value) {
      updateMap()
    }
  },
  { deep: true }
)

watch(
  [stationsStatus],
  () => {
    changeLegend()
    createNewsHTMLPopups()
  },
  { deep: true }
)

const verifyMapElement = () => {
  if (mapElement.value) {
    leafletMap?.invalidateSize()
    if (Array.isArray(coords.value) && coords.value.length === 0) {
      leafletMap = initMap(mapElement.value!)
    }
  } else {
    console.error('error')
  }
}

onMounted(verifyMapElement)
</script>

<template>
  <div id="map" ref="mapElement" class="map my-hidden animate-duration-500" />
</template>

<style scoped lang="scss">
#map {
  height: 60vh;
}

.legend-area {
  border-radius: 10px;
  padding: 5em !important;
  background: var(--lightGray) !important;
}

.leaflet-container {
  z-index: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeoout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadein {
  animation: fadein 250ms linear;
}

.fadeoout {
  animation: fadeoout 1510ms linear;
}
</style>
