<script setup lang="ts">
import { onMounted, ref } from 'vue'
import router from '@/router'
import { t } from '@/common/i18n'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import { useToast } from 'primevue/usetoast'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { storeToRefs } from 'pinia'
import { ApiService } from '@/services/ApiService'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import { capitalizeString } from '@/utils/capitalize'
import clock from '@/assets/lottie/clock.json'
import BaseInputSwitch from '@/components/ui/BaseInputSwitch.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'

interface SelectedData {
  name: string
  surname: string
  phone: string
  email: string
  active: boolean
  has_RFID: boolean
}

const defaultSelectedData: SelectedData = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  active: false,
  has_RFID: false
}
const hidden = ref<boolean>(true)
const selectedLocations = ref()
const locations = ref<Partial<Location>[]>([])
const { loading } = storeToRefs(useApi())
const user = {
  // form fields as reactive properties
  name: ref<string>(''),
  surname: ref<string>(''),
  phone: ref<string>(''),
  email: ref<string>(''),
  active: ref<boolean>(false),
  has_RFID: ref<boolean>(false)
}
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activateUser = ref<boolean>(false)
const activateRFIDCards = ref<boolean>(false)
const popup = ref()

const selectedData = ref<SelectedData>(defaultSelectedData)
const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const users = ref([])
const totalRecords = ref<number>()
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const toast = useToast()

const getLocationNames = async () => {
  try {
    loading.value = true
    locations.value = await ApiService.readAllEntities(`${RouteNamespace.locations}/names`)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
}
const getUsers = async () => {
  try {
    loading.value = true
    const usersAndTotal = await ApiService.readAllEntities(
      `${RouteNamespace.users}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    users.value = usersAndTotal[0]
    totalRecords.value = usersAndTotal[1]
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
}
const resetModal = () => {
  Object.values(user).forEach((field) => {
    field.value = ''
  })
}
const handleCreate = () => {
  activeCreationDialog.value = true
  resetModal()
}

const handleCancel = () => {
  activeUpdateDialog.value = false
  activeCreationDialog.value = false
  //remove query param
  router.push({ name: RouteNamespace.users })
}
const confirmInsert = async () => {
  try {
    loading.value = true
    const payload = {
      name: user.name.value,
      surname: user.surname.value,
      phone: user.phone.value,
      email: user.email.value,
      active: activateUser.value,
      has_RFID: activateRFIDCards.value
    }
    await ApiService.createEntity(RouteNamespace.users, payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    activeCreationDialog.value = false
    await getUsers()
    toast.add({
      group: 'create-user',
      life: 3000,
      severity: 'success',
      detail: t('detail.customer.notifications.createSuccess')
    })
    loading.value = false
  }
}

const enableActivationUser = () => {
  console.log('enabledActivationUser')
}
const handleUpdate = async (row) => {
  selectedData.value = row
  activeUpdateDialog.value = true
}
const confirmUpdate = async () => {
  try {
    loading.value = true
    const payload = {
      name: selectedData.value['name'],
      surname: selectedData.value['surname'],
      phone: selectedData.value['phone'],
      email: selectedData.value['email'],
      active: activateUser.value,
      has_RFID: activateRFIDCards.value
    }
    await ApiService.updateEntity(RouteNamespace.users, selectedData.value['id'], payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    activeUpdateDialog.value = false
    toast.add({
      group: 'edit-user',
      life: 3000,
      severity: 'success',
      detail: t('detail.customer.notifications.updateSuccess')
    })
    await getUsers()
    loading.value = false
  }
}
const actionsRemoveUser = async (row: string) => {
  try {
    await ApiService.deleteEntity(RouteNamespace.users, row['id'])
    toast.add({
      group: 'delete-user',
      life: 3000,
      severity: 'success',
      detail: t('detail.customer.notifications.deleteSuccess')
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getUsers()
  }
}
const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveUser(row)
      loading.value = false
    },
    undefined
  )
}
const showPayments = async (row) => {
  try {
    loading.value = true
    await router.push({
      path: `${RouteNamespace.payments}/${row.id}`
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
}
const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getUsers()
}

onMounted(async () => {
  try {
    loading.value = true
    totalRecords.value = users.value.length
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
    await Promise.all([getLocationNames(), getUsers()])
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="user-face" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.users') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.user.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="users"
      :globalFilterFields="['name', 'surname', 'email', 'phone', 'card.alias']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.user.notFound')" />
      </template>
      <Column
        field="name"
        :header="t('detail.user.header.name')"
        header-class="font-bold"
        class="table__name"
      >
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
          {{ capitalizeString(slotProps.data['surname']) }}
        </template>
      </Column>
      <Column
        field="email"
        :header="t('detail.user.header.customer')"
        header-class="font-bold"
        class="table__email"
      >
        <template #body="slotProps">
          {{ slotProps.data['email'] }}
        </template>
      </Column>
      <Column
        field="status"
        :header="t('detail.user.header.status')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            rounded
            outlined
            :content="slotProps.data['active'] === true ? t('status.active') : t('status.inactive')"
            :style-content="`status status__${slotProps.data['active'] === true ? 'available' : 'disabled'}`"
            :style-header="`badgeStatus badgeStatus__${slotProps.data['active'] === true ? 'available' : 'disabled'}`"
          />
        </template>
      </Column>
      <Column
        field="energy_supplied"
        :header="t('detail.user.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body>
          {{ `${(Math.random() * 10).toFixed(2)} kWh` }}
        </template>
      </Column>
      <Column
        field="rfidCards"
        :header="t('detail.user.header.rfidCards')"
        header-class="font-bold"
        class="table__card"
      >
        <template #body="slotProps">
          {{ slotProps.data['active'] ? t('yes') : t('no') }}
        </template>
      </Column>
      <Column
        field="nameRfidCard"
        :header="t('detail.user.header.nameRfidCard')"
        header-class="font-bold"
        class="table__card-name"
      >
        <template #body="slotProps">
          {{ slotProps.data['card']?.['alias'] || '---' }}
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.user.actions.payments')"
              disabled
              rounded
              class="button button-normal mr-2"
              @click="showPayments(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="payment" size="16" color="#626868" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.user.actions.update')"
              icon="pi pi-pencil"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="16" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-tooltip.top="t('detail.user.actions.delete')"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          :pt="{
            pageButton: ({ props, state, context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
    <BaseDialog
      v-model:visible="activeUpdateDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
          <div class="flex flex-row">
            <p class="p-dialog-title mr-1">{{ t('detail.user.actions.update') }} -</p>
            <p class="p-dialog-title font-family-light font-italic">
              {{ capitalizeString(selectedData['name']) }}
              {{ capitalizeString(selectedData['surname']) }}
            </p>
          </div>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
          <svg-icon name="user-face" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label for="name">{{ t('detail.user.dialog.name') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="user-face" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.name')"
                class="border-400"
                v-model="selectedData['name']"
                id="name"
                type="text"
                :value="capitalizeString(selectedData['name']) || ''"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label for="surname">{{ t('detail.user.dialog.surname') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="user" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.surname')"
                class="border-400"
                v-model="selectedData['surname']"
                id="surname"
                type="text"
                :value="capitalizeString(selectedData['surname']) || ''"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label for="phone">{{ t('detail.user.dialog.phone') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="phone" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.phone')"
                class="border-400"
                v-model="selectedData['phone']"
                id="phone"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label for="mail">{{ t('detail.user.dialog.email') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="mail" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.email')"
                class="border-400"
                v-model="selectedData['email']"
                id="email"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row align-items-center">
          <div class="field col-6">
            <label class="font-family-light" for="stations">{{
              t('detail.card.dialog.stations')
            }}</label>
            <MultiSelect
              class="h-3rem align-items-center"
              v-model="selectedLocations"
              display="chip"
              :options="locations"
              optionLabel="name"
              :placeholder="t('detail.card.dialog.placeholder.locationsToCard')"
              :pt="{
                item: ({ context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
                </div>
              </template>
            </MultiSelect>
          </div>
          <div class="field col-6">
            <div class="h-3rem">
              <label class="font-family-light" for="stations">{{
                t('detail.user.dialog.activateUser')
              }}</label>
            </div>
            <div class="flex flex-row">
              <BaseInputSwitch @change="enableActivationUser" v-model="selectedData['active']" />
              <div class="ml-3 text-600">
                {{ selectedData['active'] ? t('status.active') : t('status.inactive') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmUpdate" :remove="hidden" />
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      v-model:visible="activeCreationDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.user.actions.create') }}</p>
        </div>
        <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
          <svg-icon name="user-face" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row justify-content-between p-0 col-12">
          <div class="field col-6">
            <label for="name">{{ t('detail.user.dialog.name') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="user-face" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.name')"
                class="border-400"
                v-model="user.name.value"
                id="name"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label for="surname">{{ t('detail.user.dialog.surname') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="user" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.surname')"
                class="border-400"
                v-model="user.surname.value"
                id="surname"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label for="phone">{{ t('detail.user.dialog.phone') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="phone" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.phone')"
                class="border-400"
                v-model="user.phone.value"
                id="phone"
                type="text"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label for="mail">{{ t('detail.user.dialog.email') }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="mail" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.user.dialog.placeholder.email')"
                class="border-400"
                v-model="user.email.value"
                id="email"
                type="text"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row align-items-center">
          <div class="field col-6">
            <label class="font-family-light" for="stations">{{
              t('detail.card.dialog.stations')
            }}</label>
            <MultiSelect
              v-model="selectedLocations"
              class="h-3rem align-items-center"
              display="chip"
              :options="locations"
              optionLabel="name"
              :placeholder="t('detail.card.dialog.placeholder.locationsToCard')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
                </div>
              </template>
            </MultiSelect>
          </div>
          <div class="field col-6">
            <div class="flex flex-row align-items-center mb-1">
              <div class="flex flex-column">
                <div class="h-3rem">
                  <label class="font-family-light" for="stations">{{
                    t('detail.user.dialog.activateUser')
                  }}</label>
                </div>
                <div class="flex flex-row">
                  <BaseInputSwitch @change="enableActivationUser" v-model="activateUser" />
                  <div class="ml-3 text-600">
                    {{ activateUser ? t('status.active') : t('status.inactive') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmInsert" :remove="hidden" />
        </div>
      </template>
    </BaseDialog>
  </div>
  <BasePushNotification
    group="edit-user"
    :title="t('detail.user.notifications.updateSuccess')"
    icon-name="success"
    color="#00DB7F"
    severity="success"
    :summary="t('detail.user.notifications.updateSuccess')"
  />
  <BasePushNotification
    group="delete-user"
    :title="t('detail.user.notifications.deleteSuccess')"
    icon-name="error"
    color="#EA2839"
    severity="danger"
    :summary="t('detail.user.notifications.deleteSuccess')"
  />
  <BasePushNotification
    group="create-user"
    :title="t('detail.user.notifications.createSuccess')"
    icon-name="success"
    color="#00DB7F"
    severity="success"
    :summary="t('detail.user.notifications.createSuccess')"
  />
</template>
<style scoped lang="scss">
::v-deep(.p-inputswitch ::before) {
  background-color: var(--white) !important;
}

::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 14%;
}

::v-deep(.table__email) {
  width: 24%;
}

::v-deep(.table__status) {
  width: 12%;
}

::v-deep(.table__energy) {
  width: 13%;
}

::v-deep(.table__card) {
  width: 8%;
}

::v-deep(.table__card-name) {
  width: 12%;
}

::v-deep(.table__actions) {
  width: 17%;
}
</style>
