<script setup lang="ts">
import { type DebuggerEvent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import type { FileUploadUploaderEvent } from 'primevue/fileupload'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { i18n, t } from '@/common/i18n'
import { ApiService } from '@/services/ApiService'
import { useAuthStore } from '@/stores/auth'
import BaseGeoLocalization from '@/components/ui/BaseGeoLocalization.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import {
  type CoordinateLocation,
  type Localization,
  type Location,
  type Station,
  type StationsParams,
  type StationValueElement
} from '@/models'
import { useStatusNotification } from '@/stores/ocpp/statusNotification'
import type { LocationStationStatus } from '@/models/domain/station/component/ListStationsStatus'
import { findLocation } from '@/utils/findLocation'
import {
  ChargePointStatus,
  StationStatus,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import BaseMapContainerLocations from '@/components/ui/BaseMapContainerLocations.vue'
import type { StatusNotificationMapped } from '@/models/ocpp/StatusNotification'
import { StationStatusMap } from '@/models/domain/location/enums'
import AppTopbar from '@/layout/AppTopbar.vue'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { capitalizeString } from '@/utils/capitalize'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import { useChargerStatuses } from '@/stores/ocpp/chargerStatuses'

const toast = useToast()
const popup = ref()
const { loading } = storeToRefs(useApi())
const { customerId } = storeToRefs(useAuthStore())
const address = ref<string>('')
const city = ref<string>('')
const province = ref<string>('')
const postcode = ref<string>('')
const country = ref<string>('')
const active = ref<number>(0)
const enabled = ref<boolean>(false)
const hideMap = ref<boolean>(false)
const hidden = ref<boolean>(false)
const name = ref<string>('')
const description = ref<string>('')
const energySources = ref<string>('')
const lat = ref<number>()
const lon = ref<number>()
const numberConnectors = ref<number>(0)
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activeUploadDialog = ref<boolean>(false)
const totalRecords = ref<number>(0)
const locations = ref<Location[]>([])
const selectedLocation = ref<Location>({
  id: '',
  name: '',
  address: '',
  city: '',
  province: '',
  postcode: '',
  country: '',
  active: 0,
  status: 0,
  lat: 0,
  lon: 0,
  energySources: '',
  paidService: false,
  station: []
})
const stations = ref<StationsParams[]>([])
const coords = ref<CoordinateLocation[]>([])
const statusNotificationStore = useStatusNotification()
const locationStationStatus = ref<LocationStationStatus>({})
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const chargesStatuses = useChargerStatuses()

const statuses = ref([
  { name: t('status.available'), status: 'available', code: 0 },
  { name: t('status.unavailable'), status: 'unavailable', code: 1 },
  { name: t('status.incidence'), status: 'incidence', code: 2 },
  { name: t('status.disconnected'), status: 'disconnected', code: 3 }
])
const filters = ref({
  active: { value: null, matchMode: FilterMatchMode.IN },
  name: { value: null, matchMode: FilterMatchMode.IN }
})

const handleCreate = () => {
  activeCreationDialog.value = true
}
const confirmInsert = async () => {
  try {
    loading.value = true
    const payload = {
      address: address.value,
      name: name.value,
      city: city.value,
      province: province.value,
      postcode: postcode.value,
      country: country.value,
      active: active.value,
      lat: lat.value,
      lon: lon.value,
      energySources: energySources.value,
      description: description.value,
      customerId: customerId.value
    }
    await ApiService.createEntity(RouteNamespace.locations, payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    activeCreationDialog.value = false
    toast.add({
      group: 'locations',
      severity: 'success',
      detail: i18n.global.t('detail.location.notifications.createSuccess'),
      life: 3000
    })
    await getLocations()
    loading.value = false
  }
}
const confirmUpdate = async () => {
  try {
    loading.value = true
    const payload = {
      name: selectedLocation.value.name,
      address: selectedLocation.value.address,
      city: selectedLocation.value.city,
      province: selectedLocation.value.province,
      postcode: selectedLocation.value.postcode,
      country: selectedLocation.value.country,
      description: selectedLocation.value.description
    }
    await ApiService.updateEntity(RouteNamespace.locations, selectedLocation.value['id'], payload)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    activeUpdateDialog.value = false
    toast.add({
      group: 'locations',
      severity: 'success',
      detail: i18n.global.t('detail.location.notifications.updateSuccess'),
      life: 3000
    })

    await getLocations()
    loading.value = false
  }
}
const handleDetail = (row: Event) => {
  router.push({
    name: RouteNamespace.location,
    params: { id: row['data']['id'] }
  })
}
const handleCancel = () => {
  activeCreationDialog.value = false
  activeUpdateDialog.value = false
  //remove query param
  router.push({ name: RouteNamespace.locations })
}
const sumConnectorsByCustomer = async () => {
  const id = customerId.value
  try {
    numberConnectors.value = await ApiService.readEntity(
      RouteNamespace.connectors,
      `${id}/customer/sum`
    )
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
const addCoord = (location: Location) => {
  const { id, name, lat, lon } = location
  coords.value.push([id as string, name as string, lat as number, lon as number])
}

const addStation = (location: Location) => {
  const locationId = location.id
  location.station.forEach((station: Station) => {
    const stationId = station.id
    const chargerId = station.charger.id
    const chargerName = station.charger.name
    const statusCharger = station.status
    const existingLocationIndex = stations.value.findIndex(
      (value: StationValueElement) => value[0] === locationId
    )
    if (existingLocationIndex === -1) {
      stations.value.push([
        locationId,
        [
          {
            stationId,
            chargerId,
            chargerName,
            statusCharger
          }
        ]
      ])
    } else {
      stations.value[existingLocationIndex][1].push({
        stationId,
        chargerId,
        chargerName,
        statusCharger
      })
    }
  })
}
const handleUpdate = (rowData: Location) => {
  activeUpdateDialog.value = true
  selectedLocation.value = rowData
}
const handleUploadDialog = () => {
  activeUploadDialog.value = true
}
const handleRemoveLocation = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveLocation(id)
      loading.value = false
    },
    undefined
  )
}
const actionsRemoveLocation = async (id: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntity(RouteNamespace.locations, id, payload)
    toast.add({
      group: 'station-remove',
      severity: 'error',
      life: 3000,
      detail: t('detail.station.notifications.deleteSuccess')
    })
  } catch (error) {
    console.error('Error deleting station:', error)
  } finally {
    await getLocations()
  }
}
const getLocations = async () => {
  try {
    stations.value = []
    const result = await ApiService.readAllEntities(
      `${RouteNamespace.locations}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    locations.value = result[0]
    if (coords.value.length !== 0) coords.value = [] //reset coords
    locations.value.forEach(addCoord)
    locations.value.forEach(addStation)
    totalRecords.value = result[1]
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}

const updateAddress = (e: Localization) => {
  address.value = e.address_line1
  city.value = e.city
  country.value = e.country
  postcode.value = e.postcode
  province.value = e.county
  lat.value = e.lat
  lon.value = e.lon
}

//Prepara el objeto donde se guardaran los estados del cargador
const sortStationsByLocations = (data: Location[]) => {
  let status: ChargePointStatus | StationStatusMap = StationStatusMap.DISCONNECTED
  data.forEach((location: Location) => {
    locationStationStatus.value[location.id] = []
    location.station.forEach((station: Station) => {
      const statusWebsocket = chargesStatuses.chargerStatuses.get(station.cpId)
      if (statusWebsocket === ChargerWsStatus.CONNECTED) {
        const statusNotification = statusNotificationStore.statusNotification.get(
          `${station.cpId}:0`
        )
        if (statusNotification) {
          status = statusNotification.status
        }
      }
      locationStationStatus.value[location.id].push({ [station.cpId]: status })
      status = StationStatusMap.DISCONNECTED
    })
  })
}

const getStationsStatus = (newValue: StatusNotificationMapped) => {
  const { cpId, status } = newValue
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  let found = false
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      locationStationStatus.value[locationId][i][cpId] = StationStatus[status.toUpperCase()]
      found = true
    }
  }
}

const getStatusDisconnection = (cpId: string) => {
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  let found = false
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      locationStationStatus.value[locationId][i][cpId] = StationStatusMap.DISCONNECTED
      found = true
    }
  }
}

const getStatusConnection = (cpId: string) => {
  let found = false
  const completeStatus = statusNotificationStore.statusNotification.get(`${cpId}:0`)
  if (!completeStatus) return
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      const status = ChargePointStatus[completeStatus.status]
      if (status) locationStationStatus.value[locationId][i][cpId] = status
      found = true
    }
  }
}

const updateStatusStations = () => {
  statusNotificationStore.$subscribe((mutation) => {
    try {
      if (Array.isArray(mutation.events)) {
        mutation.events.forEach((event: DebuggerEvent) => {
          if (!event.newValue) return
          if (event.newValue.connectorId !== 0) return
          getStationsStatus(event.newValue)
        })
      } else {
        if (!mutation.events.newValue) return
        if (mutation.events.newValue.connectorId !== 0) return
        getStationsStatus(mutation.events.newValue)
      }
    } catch (err) {
      console.error('Failed to register the new connector status')
    }
  })
}

const getStationStatusWebsocket = (cpId: string, newValue: ChargerWsStatus) => {
  if (newValue === ChargerWsStatus.CONNECTED) {
    getStatusConnection(cpId)
    return
  }
  getStatusDisconnection(cpId)
}

const subscribeChargerStatuses = () => {
  chargesStatuses.$subscribe((mutation) => {
    try {
      if (Array.isArray(mutation.events)) {
        mutation.events.forEach((event: DebuggerEvent) => {
          if (!event.newValue) return
          if (!event.key) return
          getStationStatusWebsocket(event.key, event.newValue)
        })
      } else {
        if (!mutation.events.newValue) return
        if (!mutation.events.key) return
        getStationStatusWebsocket(mutation.events.key, mutation.events.newValue)
      }
    } catch (err) {
      console.error('Failed to register the new websocket status')
    }
  })
}
const handleUploadCSV = async (event: FileUploadUploaderEvent) => {
  try {
    loading.value = true
    let file: File
    if (Array.isArray(event.files)) {
      file = event.files[event.files.length - 1]
    } else {
      file = event.files
    }
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('customerId', customerId.value)
      const response = await ApiService.createEntity(RouteNamespace.uploadCSVLocations, formData)
      if (response) {
        //Necesario ponerlo por el catch de createEntity devuelve never[]
        if (!Array.isArray(response)) {
          if (response.status >= 200 && response.status <= 299) {
            await getLocations()
            loading.value = false
          }
        }
      }
    }
  } catch (error) {
    console.error('Error handling upload:', error)
  } finally {
    activeUploadDialog.value = false
  }
}

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getLocations()
}

onMounted(async () => {
  try {
    loading.value = true
    enabled.value = true
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
    await Promise.all([getLocations(), sumConnectorsByCustomer()])
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
    updateStatusStations()
    sortStationsByLocations(locations.value)
    subscribeChargerStatuses()
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center gap-4">
          <div class="flex">
            <svg-icon name="location" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.locations') }}</span>
          </div>
          <div class="flex justify-content-center gap-3">
            <Button
              v-tooltip.top="t('detail.location.actions.createBulk')"
              rounded
              class="button button-save shadow-1"
              @click="handleUploadDialog"
            >
              <template #icon>
                <i class="font-bold pi pi-upload text-lg text-white" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.location.actions.create')"
              rounded
              class="button button-normal shadow-1"
              @click="handleCreate"
            >
              <template #icon>
                <svg-icon name="add" size="20" color="white" />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row gap-4 justify-content-between">
        <div class="flex flex-row gap-2 overflow-hidden">
          <MultiSelect
            v-model="filters['name'].value"
            class="multi-select align-items-center"
            :maxSelectedLabels="3"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.location')"
            :options="locations"
            optionLabel="name"
            optionValue="name"
            :loading
          >
            <template #dropdownicon>
              <svg-icon name="location" size="18" color="#9E9E9E" />
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ capitalizeString(slotProps.option.name) }}</div>
              </div>
            </template>
          </MultiSelect>
          <MultiSelect
            v-model="filters['active'].value"
            class="multi-select align-items-center"
            :maxSelectedLabels="4"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.status')"
            :options="statuses"
            optionLabel="name"
            optionValue="code"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div :class="`status status__${slotProps.option.status} mr-1`" />
                <small
                  class="font-bold"
                  :class="`badgeStatus badgeStatus__${slotProps.option.status}`"
                  >{{ capitalizeString(slotProps.option.name) }}</small
                >
              </div>
            </template>
          </MultiSelect>
        </div>
        <ToggleButton
          v-model="hideMap"
          v-styleclass="{ selector: '.map', enterActiveClass: 'fadein' }"
          class="toggle-button mr-2"
          :onLabel="t('topbar.showMap')"
          :offLabel="t('topbar.hideMap')"
          icon-pos="left"
          onIcon="pi pi-eye"
          offIcon="pi pi-eye-slash"
        />
      </div>
    </template>
  </AppTopbar>
  <section>
    <div class="card h-fit bg-white shadow-none">
      <BaseMapContainerLocations
        v-show="!hideMap"
        :zoom="5"
        :isDraggable="false"
        :coords="coords"
        :stations="stations"
        :stationsStatus="locationStationStatus"
        incidence="3"
      />
      <DataTable
        v-model:filters="filters"
        :value="locations"
        :globalFilterFields="['name', 'active']"
        size="large"
        :rows="rowsPerPage"
        :rowHover="true"
        dataKey="id"
        @rowClick="handleDetail($event)"
        scrollable
      >
        <template #empty>
          <BaseLottieAnimation :icon="clock" :label="t('detail.location.notFound')" />
        </template>
        <Column
          field="name"
          :header="t('detail.location.header.name')"
          header-class="font-bold"
          :showClearButton="true"
          :showFilterMenu="false"
          class="table__name"
        >
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              <span>{{ capitalizeString(data.name) }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="address"
          :header="t('detail.location.header.address')"
          header-class="font-bold"
          class="table__address"
        >
          <template #body="slotProps">
            {{ capitalizeString(slotProps.data['address']) }}
          </template>
        </Column>
        <Column
          field="charger"
          :header="t('detail.location.header.chargePoints')"
          header-class="table__header font-bold"
          rowStyleClass="bg-gray-100"
          class="table__chargers"
        >
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Tag :value="slotProps.data['station'].length" rounded />
            </div>
          </template>
        </Column>
        <Column :header="t('detail.header.actions')" header-class="table__header font-bold">
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Button
                class="button button-normal mr-2"
                v-tooltip.top="t('detail.location.actions.update')"
                rounded
                @click="handleUpdate(slotProps.data)"
              >
                <template #icon>
                  <svg-icon name="edit" size="18" color="#626868" />
                </template>
              </Button>
              <BaseConfirmDeletePopup ref="popup" />
              <Button
                class="button button-remove"
                v-tooltip.top="t('detail.location.actions.delete')"
                rounded
                @click="handleRemoveLocation($event, slotProps.data.id)"
              >
                <template #icon>
                  <svg-icon name="trash" size="16" />
                </template>
              </Button>
            </div>
          </template>
        </Column>
        <template #footer>
          <div class="flex flex-row justify-content-center align-items-center">
            <Paginator
              @page="onPageChange"
              class="flex justify-content-center"
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              :pt="{
                pageButton: ({ props, state, context }) => ({
                  class: context.active ? 'bg-gray-500 text-white' : undefined
                })
              }"
            />
          </div>
        </template>
      </DataTable>
    </div>
    <BaseDialog
      v-model:visible="activeCreationDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.location.actions.create') }}</p>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mr-3">
          <svg-icon name="location" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row">
          <div class="field col-12">
            <label for="name" class="font-family-light required">{{
              t('detail.location.header.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="location-name" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="name"
                id="name"
                type="text"
                :placeholder="t('detail.location.dialog.placeholder.name')"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-12">
            <span class="font-family-light">
              <BaseGeoLocalization
                class="relative"
                @select-address="updateAddress"
                :placeholder="t('detail.location.dialog.placeholder.address')"
              />
            </span>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="city">{{
              t('detail.customer.header.city')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="city" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                class="mt-1"
                v-model="city"
                id="city"
                type="text"
                :placeholder="t('detail.location.dialog.placeholder.city')"
              />
            </IconField>
          </div>
          <div class="field col-6 md:col-6">
            <label class="font-family-light" for="province">{{
              t('detail.customer.header.province')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="province" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.province')"
                class="mt-1"
                v-model="province"
                id="province"
                type="text"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="postcode">{{
              t('detail.customer.header.postcode')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="postcode" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.postcode')"
                class="mt-1"
                v-model="postcode"
                id="postcode"
                type="text"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="country">{{
              t('detail.customer.header.country')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="country" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.country')"
                class="mt-1"
                v-model="country"
                id="country"
                type="text"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12">
            <label class="font-family-light" for="description">{{
              t('detail.customer.header.description')
            }}</label>
            <Textarea
              v-model="description"
              rows="5"
              cols="30"
              id="description"
              type="text"
              autoResize
              :placeholder="t('detail.location.dialog.placeholder.description')"
            />
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12">
            <label class="font-family-light" for="description">{{
              t('detail.location.dialog.placeholder.energySources')
            }}</label>
            <Textarea
              v-model="energySources"
              rows="5"
              cols="30"
              id="description"
              type="text"
              autoResize
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmInsert" :remove="!hidden" />
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      v-model:visible="activeUpdateDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.location.actions.update') }}</p>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mr-3">
          <svg-icon name="location" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row">
          <div class="field col-12">
            <label for="name" class="font-family-light required">{{
              t('detail.location.header.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="location-name" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="selectedLocation.name"
                id="name"
                type="text"
                :placeholder="t('detail.location.dialog.placeholder.name')"
                :value="capitalizeString(selectedLocation.name) || ''"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-12">
            <span class="font-family-light">
              <BaseGeoLocalization
                class="relative"
                :address="selectedLocation['address']"
                @select-address="updateAddress"
                :placeholder="t('detail.location.dialog.placeholder.address')"
              />
            </span>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="city">{{
              t('detail.customer.header.city')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="city" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                class="mt-1"
                v-model="selectedLocation.city"
                id="city"
                type="text"
                disabled
                :placeholder="t('detail.location.dialog.placeholder.city')"
                :value="capitalizeString(selectedLocation.city) || ''"
              />
            </IconField>
          </div>
          <div class="field col-6 md:col-6">
            <label class="font-family-light" for="province">{{
              t('detail.customer.header.province')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="province" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.province')"
                class="mt-1"
                v-model="selectedLocation.province"
                id="province"
                type="text"
                disabled
                :value="capitalizeString(selectedLocation?.province)"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="postcode">{{
              t('detail.customer.header.postcode')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="postcode" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.postcode')"
                class="mt-1"
                v-model="selectedLocation.postcode"
                id="postcode"
                type="text"
                disabled
                :value="selectedLocation?.postcode"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
          <div class="field col-12 md:col-6">
            <label class="font-family-light" for="country">{{
              t('detail.customer.header.country')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="country" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                :placeholder="t('detail.location.dialog.placeholder.country')"
                class="mt-1"
                v-model="selectedLocation.country"
                id="country"
                type="text"
                disabled
                :value="capitalizeString(selectedLocation?.country) || ''"
                :pt="{
                  root: { class: 'disabled-field' }
                }"
              />
            </IconField>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12">
            <label class="font-family-light" for="description">{{
              t('detail.customer.header.description')
            }}</label>
            <div class="flex flex-row justify-content-start">
              <Textarea
                v-model="selectedLocation.description"
                rows="5"
                cols="30"
                id="description"
                type="text"
                autoResize
              />
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12">
            <label class="font-family-light" for="energySources">{{
              t('detail.location.dialog.placeholder.energySources')
            }}</label>
            <div class="flex flex-row justify-content-start">
              <Textarea
                v-model="selectedLocation['energySources']"
                rows="5"
                cols="30"
                id="energySources"
                type="text"
                autoResize
                disabled
                :value="JSON.stringify(selectedLocation['energySources'])"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <FooterDialog @cancel="handleCancel" @confirm="confirmUpdate" :remove="!hidden" />
        </div>
      </template>
    </BaseDialog>
    <BaseDialog
      v-model:visible="activeUploadDialog"
      :closable="false"
      :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    >
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.location.actions.createBulk') }}</p>
        </div>
      </template>
      <template #header>
        <div class="absolute top-0 right-0 mt-4 mr-3">
          <svg-icon name="location" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-row">
          <div class="field col-12">
            <FileUpload
              name="file"
              accept=".csv"
              url="/api/upload"
              :previewWidth="0"
              :customUpload="true"
              :showUploadButton="true"
              :chooseLabel="t('actions.import')"
              :uploadLabel="t('actions.upload')"
              :cancelLabel="t('actions.cancel')"
              :pcFileBadge="t('actions.upload')"
              :maxFileSize="1000000"
              @uploader="handleUploadCSV"
            />
          </div>
        </div>
      </template>
    </BaseDialog>
    <BasePushNotification
      :title="t('notifications.stationCreated')"
      icon-name="success"
      color="#00DB7F"
      group="locations"
      severity="success"
      :summary="t('notifications.stationCreatedSummary')"
    />
    <BasePushNotification
      :title="t('detail.location.notifications.updateSuccess')"
      icon-name="success"
      color="#00DB7F"
      group="locations"
      severity="success"
      :summary="t('notifications.stationCreatedSummary')"
    />
  </section>
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 27%;
}

::v-deep(.table__address) {
  width: 42%;
}

::v-deep(.table__chargers) {
  width: 15%;
}

::v-deep(.table__actions) {
  width: 16%;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}

::v-deep(.toggle-button) {
  min-width: 11rem;
  min-height: 40px;
}
</style>
